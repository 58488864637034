import React from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Form,
  FormGroup
} from 'reactstrap';

// WS Logo
import wsLogo from '../.././assets/ws-main-logo.svg';

export default class SubscribeButton extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      modal: false,
      email: '',
      name: '',
      emailInputCss: '',
      nameInputCss: '',
      nameErrMsg: 'Please enter your name',
      emailErrMsg: 'Please enter your email',
      showTooltipName: false,
      showTooltipEmail: false
    };
    this.nameInput = React.createRef();
    this.emailInput = React.createRef();
  }
  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }
  handleEmailChange = e => {
    this.setState({
      email: e.target.value,
      emailInputCss: ''
    });
  };
  handleNameChange = e => {
    this.setState({
      name: e.target.value,
      nameInputCss: ''
    });
  };
  showTooltipName = () => {
    this.setState({
      showTooltipName: true
    });
  };
  hideTooltipName = () => {
    this.setState({
      showTooltipName: false
    });
  };
  showTooltipEmail = () => {
    this.setState({
      showTooltipEmail: true
    });
  };
  hideTooltipEmail = () => {
    this.setState({
      showTooltipEmail: false
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    if (
      this.emailInput.current.props.value ||
      this.nameInput.current.props.value
    ) {
      this.setState({
        nameInputCss: this.nameInput.current.props.value ? '' : 'danger',
        emailInputCss: this.emailInput.current.props.value ? '' : 'danger'
      });
      this.validateEmail(this.state.email);
    } else {
      this.setState({
        nameInputCss: this.nameInput.current.props.value ? '' : 'danger',
        emailInputCss: this.emailInput.current.props.value ? '' : 'danger'
      });
    }
  };

  validateEmail = email => {
    //eslint-disable-next-line
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = re.test(String(email).toLowerCase());
    if (isValid) {
      this.sendProspect(this.state.name, this.state.email);
    } else {
      this.setState({
        emailInputCss: 'danger',
        emailErrMsg: 'Please enter a valid email'
      });
    }
  };
  sendProspect = (name, email) => {
    let emailObj = {
      name: name,
      email: email,
      source: 'https://www.workforcesuccess.com/'
    };
    fetch('https://hooks.zapier.com/hooks/catch/3349564/ls0pvm/', {
      method: 'POST',
      body: JSON.stringify(emailObj)
    })
      .then(res => {
        console.log(res);
        this.setState({
          emailInputCss: '',
          nameInputCss: '',
          modal: !this.state.modal
        });
      })
      .catch(err => console.log(err));
  };
  render() {
    return (
      <React.Fragment>
        <Button color="primary" className="subscribe-btn" onClick={this.toggle}>
          {this.props.text ? this.props.text : 'SUBSCRIBE'}
        </Button>
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          className="modal-dialog-centered"
          id="subscriberModal"
        >
          <Form onSubmit={this.handleSubmit}>
            <FormGroup>
              <ModalHeader toggle={this.toggle} />
              <ModalBody>
                <img src={wsLogo} className="mb-4" alt={'wslogo'} />
                <p className="font-poppins mb-4">
                  Get more Workforce Success resources, right in your inbox.
                </p>
                <div
                  className={`input-div ${this.state.nameInputCss} ${
                    this.state.showTooltipName
                      ? 'show-tooltip-email'
                      : 'hide-tooltip'
                  }`}
                >
                  <Input
                    name="name"
                    type="text"
                    placeholder="Enter your name"
                    ref={this.nameInput}
                    onChange={this.handleNameChange}
                    value={this.state.name}
                  />
                  <div
                    className="error-marker"
                    onMouseEnter={this.showTooltipName}
                    onMouseLeave={this.hideTooltipName}
                  >
                    !
                  </div>
                  <div className="tooltip bs-tooltip-bottom">
                    <div className="arrow" />
                    <div className="tooltip-inner font-work-sans">
                      {this.state.nameErrMsg}
                    </div>
                  </div>
                </div>
                <div className="mb-4" />
                <div
                  className={`input-div ${this.state.emailInputCss} ${
                    this.state.showTooltipEmail
                      ? 'show-tooltip-email'
                      : 'hide-tooltip'
                  }`}
                >
                  <Input
                    name="email"
                    type="text"
                    placeholder="Enter your email"
                    ref={this.emailInput}
                    onChange={this.handleEmailChange}
                    value={this.state.email}
                  />
                  <div
                    className="error-marker"
                    onMouseEnter={this.showTooltipEmail}
                    onMouseLeave={this.hideTooltipEmail}
                  >
                    !
                  </div>
                  <div className="tooltip bs-tooltip-bottom">
                    <div className="arrow" />
                    <div className="tooltip-inner font-work-sans">
                      {this.state.emailErrMsg}
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button
                  type="submit"
                  color="primary"
                  // onClick={this.toggle}
                  className="font-poppins"
                >
                  SUBSCRIBE
                </Button>
              </ModalFooter>
            </FormGroup>
          </Form>
        </Modal>
      </React.Fragment>
    );
  }
}
