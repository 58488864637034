import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import './ConferenceBanner.css';
import { ReactComponent as Logo } from '../../assets/wscon-2019-logo.svg';
import { ReactComponent as LogoMobile } from '../../assets/wscon2019-logo-mobile.svg';

const dateEvent = '2019/07/26 08:00:00';
export default class EventAbout extends Component {
  constructor(props) {
    super(props);
    let auCD = new Date().toLocaleString('en-US', {
      timeZone: 'Australia/Brisbane'
    });
    let auED = new Date(dateEvent).toLocaleString('en-US', {
      timeZone: 'Australia/Brisbane'
    });
    let currentDate = new Date(auCD);
    let eventDate = new Date(auED);
    let difference = eventDate.getTime() - currentDate.getTime();
    if (difference >= 0) {
      this.state = {
        status: false,
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000)
      };
    } else {
      this.state = {
        status: true,
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0
      };
    }
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.tick(), 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  tick() {
    let auCD = new Date().toLocaleString('en-US', {
      timeZone: 'Australia/Brisbane'
    });
    let auED = new Date(dateEvent).toLocaleString('en-US', {
      timeZone: 'Australia/Brisbane'
    });
    let currentDate = new Date(auCD);
    let eventDate = new Date(auED);
    let difference = eventDate.getTime() - currentDate.getTime();
    if (difference >= 0) {
      this.setState({
        days: Math.floor(difference / (1000 * 3600 * 24)),
        hours: Math.floor(
          (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        ),
        minutes: Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)),
        seconds: Math.floor((difference % (1000 * 60)) / 1000)
      });
    } else {
      this.setState({
        status: true
      });
    }
  }

  loadLogo() {
    if (window.innerWidth > 991) {
      return <Logo />;
    } else {
      return <LogoMobile />;
    }
  }

  buyText() {
    if (window.innerWidth < 576) {
      return 'Buy';
    } else {
      return 'Buy Tickets';
    }
  }

  eventBrite() {
    window.open(
      'https://www.eventbrite.com.au/e/workforce-success-conference-2019-tickets-52300325682?ref=ebtn#tickets',
      '_blank'
    );
  }

  render() {
    return (
      <React.Fragment>
        <div
          className={`${
            this.state.status ? 'conference__banner__hide' : ''
          } conference__banner`}
        >
          <Row>
            <Col xl="4" lg="3" md="3" sm="3" xs="3" className="my-auto">
              <div>
                <a href="/conference">{this.loadLogo()}</a>
              </div>
            </Col>
            <Col xl="4" lg="6" md="7" sm="5" xs="7" className="my-auto">
              <div>
                <div>
                  <p>{this.state.days}</p>
                  <span>{this.state.days === 1 ? 'Day' : 'Days'}</span>
                </div>
                <div>
                  <p>{this.state.hours}</p>
                  <span>{this.state.hours === 1 ? 'Hour' : 'Hours'}</span>
                </div>
                <div>
                  <p>{this.state.minutes}</p>
                  <span>{this.state.minutes === 1 ? 'Minute' : 'Minutes'}</span>
                </div>
                <div>
                  <p>{this.state.seconds}</p>
                  <span>{this.state.seconds === 1 ? 'Second' : 'Seconds'}</span>
                </div>
              </div>
            </Col>
            <Col xl="2" lg="3" md="2" sm="4" xs="2">
              <div onClick={this.eventBrite}>
                <p>{this.buyText()}</p>
              </div>
            </Col>
            <Col xl="2" lg="2" md="2" sm="2" xs="2">
              <div />
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
