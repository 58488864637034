import React from 'react';
import { Row, Col } from 'reactstrap';
import YouTube from 'react-youtube';
import './EventVideos.css';
import './EventVideos_m.css';
import axios from 'axios';
import Truncate from 'react-truncate';
import TimeAgo from 'react-timeago';
import nowPlaying from './../../assets/now-playing.png';

export default class EventVideos extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      currentVideoId: '',
      autoPlay: 0,
      currentPlay: 0,
      opts: {
        height: '430',
        width: '720'
      }
    };
  }

  handleYoutubeRequest = () => {
    axios
      .get(
        'https://content.googleapis.com/youtube/v3/playlistItems?maxResults=30&part=snippet&playlistId=PLQEXy9lzj3M1bOKQwIkMFt-rH8pbPeTos&key=AIzaSyB5REqWPr2ynsMC5WbBEnBVbHfmPzoFHHU'
      )
      .then(
        function(response) {
          this.setState({
            currentVideoId: response.data.items[0].snippet.resourceId.videoId,
            items: response.data.items
          });
        }.bind(this)
      );
  };

  playVideo = (newId, i) => {
    this.setState({ currentVideoId: newId, autoPlay: 1, currentPlay: i });
  };

  componentDidMount = () => {
    this.handleYoutubeRequest();
  };

  renderThumbnails(items) {
    // var idList = [];
    const thumbnails = items.map((item, i) => (
      <Row
        className="thumbnails__row"
        key={i}
        onClick={() => this.playVideo(item.snippet.resourceId.videoId, i)}
      >
        <Col lg="6">
          <div
            className="thumbnail__image"
            style={{
              backgroundImage: `url(${item.snippet.thumbnails.default.url})`
            }}
          />
        </Col>
        <Col lg="6">
          <p className="thumbnail__title">
            <Truncate lines={2}>{item.snippet.title}</Truncate>
          </p>
          <p className="thumbnail__subtitle">
            <img
              className={
                this.state.currentVideoId === item.snippet.resourceId.videoId
                  ? 'now__playing'
                  : 'now__playing__hidden'
              }
              src={nowPlaying}
              alt="now playing"
            />
            <TimeAgo date={item.snippet.publishedAt} />
          </p>
        </Col>
      </Row>
    ));

    return <ul className="events__list">{thumbnails}</ul>;
  }

  render() {
    const opts = {
      height: this.state.opts.height,
      width: this.state.opts.width,
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: this.state.autoPlay
      }
    };

    return (
      <Row>
        <Col lg="8" className="youtube__player">
          <YouTube
            videoId={this.state.currentVideoId}
            opts={opts}
            onReady={this._onReady}
          />
        </Col>
        <Col lg="4" className="events__playlist">
          {this.renderThumbnails(this.state.items)}
        </Col>
      </Row>
    );
  }

  _onReady(event) {
    event.target.pauseVideo();
  }
}
