import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import axios from 'axios';
import _ from 'lodash';
import config from '../.././config.json';
import readingTime from 'reading-time';

// Styles
import './CategoryPage.css';

// Logos
import employeeEmpowertmentLogo from '../.././assets/employee-empowerment.png';
import successHacksLogo from '../.././assets/success-hacks.png';
import globalFirstsLogo from '../.././assets/global-fIrsts.png';
import headsUpLogo from '../.././assets/heads-up.png';
import cultureLogo from '../.././assets/culture.png';

// Components
import CategoryPagePagination from '../.././components/CategoryPagePagination/CategoryPagePagination';
import SubscribeBox from '../../components/SubscribeBox/SubscribeBox';
import SubscribeBoxFooter from '../../components/SubscribeBoxFooter/SubscribeBoxFooter';
import ArticleCard from '../../components/ArticleCard/ArticleCard';
// import ArticleMostPopular from '../../components/ArticleMostPopular/ArticleMostPopular';

export default class CategoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      postsChunks: [],
      currentArr: 0,
      category: [],
      loading: true
    };
  }

  handleArticleRequest = () => {
    axios
      .all([
        axios.get(
          `${config.databaseUrl}wp-json/wp/v2/posts?categories=${
            this.props.match.params.id
          }&per_page=100&_embed`
        ),
        axios.get(
          `${config.databaseUrl}wp-json/wp/v2/categories?slug=${
            this.props.match.params.slug
          }`
        )
      ])

      .then(
        axios.spread((posts, category) => {
          this.setState({
            posts: posts.data,
            loading: false,
            postsChunks: _.chunk(posts.data, 6),
            category: category.data,
            currentArr: 0
          });
          // console.log(category.data);
        })
      )
      .catch(error => console.log(error));
  };
  pager = currentPageNo => {
    this.setState({
      currentArr: currentPageNo
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.handleArticleRequest();
      return true;
    }
  }
  componentDidMount() {
    this.handleArticleRequest();
  }
  determineOverflow = (content, container) => {
    let containerMetrics = container.getBoundingClientRect();
    let containerMetricsRight = Math.floor(containerMetrics.right);
    let containerMetricsLeft = Math.floor(containerMetrics.left);
    let contentMetrics = content.getBoundingClientRect();
    let contentMetricsRight = Math.floor(contentMetrics.right);
    let contentMetricsLeft = Math.floor(contentMetrics.left);
    if (
      containerMetricsLeft > contentMetricsLeft &&
      containerMetricsRight < contentMetricsRight
    ) {
      return 'both';
    } else if (contentMetricsLeft < containerMetricsLeft) {
      return 'left';
    } else if (contentMetricsRight > containerMetricsRight) {
      return 'right';
    } else {
      return 'none';
    }
  };
  handleLogos = slug => {
    if (slug === 'employee-empowerment') {
      return employeeEmpowertmentLogo;
    }
    if (slug === 'success-hacks') {
      return successHacksLogo;
    }
    if (slug === 'global-firsts') {
      return globalFirstsLogo;
    }
    if (slug === 'heads-up') {
      return headsUpLogo;
    }
    if (slug === 'culture') {
      return cultureLogo;
    }
    // return 'false';
  };
  createArticleCards = (posts, chunks) => {
    let bgColorArr = ['bg-ub', 'bg-rp', 'bg-tt'];
    let randomNum = _.random(0, 2);

    if (posts.length > 1) {
      let postsChunks = chunks;
      let articleCardsEl = postsChunks[this.state.currentArr].map(
        (posts, index) => {
          let featuredImageFile;
          let featuredMedia = posts._embedded['wp:featuredmedia'];
          if (posts._embedded['wp:featuredmedia']) {
            if (featuredMedia[0].media_details.sizes.medium) {
              featuredImageFile =
                featuredMedia[0].media_details.sizes.medium.source_url;
            } else {
              featuredImageFile = '';
            }
          } else {
            featuredImageFile = '';
          }
          return (
            <div
              className="long no-label no-category no-preview no-author-img mb-4"
              key={index}
            >
              <ArticleCard
                featuredImg={featuredImageFile}
                title={posts.title.rendered}
                authorImg={
                  posts._embedded.author[0].avatar_urls[
                    Object.keys(posts._embedded.author[0].avatar_urls)[2]
                  ]
                }
                authorName={posts._embedded.author[0].name}
                datePosted={posts.date}
                minutesToRead={readingTime(posts.content.rendered).text}
                bgcolor={bgColorArr[randomNum]}
                url={posts.slug}
                type="long"
              />
            </div>
          );
        }
      );
      return articleCardsEl;
    } else if (posts.length === 1) {
      let postsChunks = posts;
      return (
        <div className="long no-label no-category no-preview no-author-img mb-4">
          <ArticleCard
            featuredImg={
              postsChunks[0]._embedded['wp:featuredmedia'][0].media_details
                .sizes.medium
                ? postsChunks[0]._embedded['wp:featuredmedia'][0].media_details
                    .sizes.medium.source_url
                : ''
            }
            title={postsChunks[0].title.rendered}
            authorImg={
              postsChunks[0]._embedded.author[0].avatar_urls[
                Object.keys(postsChunks[0]._embedded.author[0].avatar_urls)[2]
              ]
            }
            authorName={postsChunks[0]._embedded.author[0].name}
            datePosted={postsChunks[0].date}
            minutesToRead={readingTime(postsChunks[0].content.rendered).text}
            bgcolor={bgColorArr[randomNum]}
            url={postsChunks[0].slug}
            type="long"
          />
        </div>
      );
    } else {
      return (
        <p className="text-center">No posts available for this category</p>
      );
    }
  };
  render() {
    if (!this.state.loading) {
      return (
        <React.Fragment>
          <Container id="categoryPage">
            <Row className="header mb-5">
              <Col>
                <img
                  src={this.handleLogos(this.props.match.params.slug)}
                  className={`logo mx-auto  ${
                    this.handleLogos(this.props.match.params.slug)
                      ? 'd-block'
                      : 'd-none'
                  }`}
                  alt="WS Category Logo"
                />
                <p className="h3 text-center font-weight-bold text-uppercase">
                  {this.state.category['0'].name}
                </p>
                <p className="text-center font-size-sh">
                  {this.state.category['0'].description}
                </p>
                <span className="fw-border-bottom" />
              </Col>
            </Row>

            <Row className="pt-3 mb-5">
              <Col lg="8">
                {this.createArticleCards(
                  this.state.posts,
                  this.state.postsChunks
                )}
              </Col>
              <Col lg="4">
                <SubscribeBox />
                {/* <div className="most-popular mt-5">
                  <p className="h3 font-weight-bold mb-4">RECOMMENDED</p>
                  <ArticleMostPopular
                    bgcolor="bg-rp"
                    category="Productivity hacks"
                    title="Get Ahead In Your Career By Asking These Questions"
                    url="#"
                  />
                  <ArticleMostPopular
                    bgcolor="bg-tt"
                    category="heads up"
                    title="Get Ahead In Your Career By Asking These Questions"
                    url="#"
                  />
                  <ArticleMostPopular
                    bgcolor="bg-ub"
                    category="Global first"
                    title="25 Creative Restaurant Marketing Ideas To Get More Customers"
                    url="#"
                  />
                </div> */}
              </Col>
            </Row>
            <Row>
              <Col lg="8" className="d-flex justify-content-center">
                <CategoryPagePagination
                  postArr={this.state.postsChunks}
                  pager={this.pager}
                  currentPageNo={this.state.currentArr}
                />
              </Col>
            </Row>
            <Row className="pb-4 mt-5r">
              <Col>
                <SubscribeBoxFooter />
              </Col>
            </Row>
          </Container>
        </React.Fragment>
      );
    } else {
      return (
        <div className="h-100vh d-flex align-items-center justify-content-center">
          <p className="text-center">Loading...</p>
        </div>
      );
    }
  }
}
