import React, { Component } from 'react';
import './App.css';
import Header from './components/Header/HeaderContainer';
import Footer from './components/Footer/Footer';

import { Route, Switch, BrowserRouter, Redirect } from 'react-router-dom';
import Homepage from './modules/Homepage/Homepage';
import AboutPage from './modules/AboutPage/AboutPage';
import PrivacyPolicyPage from './modules/PrivacyPolicyPage/PrivacyPolicyPage';
import CookieStatementPage from './modules/CookieStatementPage/CookieStatementPage';
import EventsPage from './modules/EventsPage/EventsPage';
import SinglePostPage from './modules/SinglePostPage/SinglePostPage';
import CategoryPage from './modules/CategoryPage/CategoryPage';
import NotFound from './modules/Error404Page/Error404Page';
import SearchResultsPage from './modules/SearchResultsPage/SearchResultsPage';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faTachometerAlt,
  faUsers,
  faImages,
  faClipboardList,
  faFileAlt,
  faThList,
  faSearch,
  faSignOutAlt,
  faUser,
  faPen,
  faArchive,
  faAngleUp
} from '@fortawesome/free-solid-svg-icons';

library.add(
  fab,
  faTachometerAlt,
  faUsers,
  faImages,
  faClipboardList,
  faFileAlt,
  faThList,
  faSearch,
  faUser,
  faSignOutAlt,
  faPen,
  faArchive,
  faAngleUp
);
class App extends Component {
  render() {
    return (
      <React.Fragment>
        <BrowserRouter>
          <div>
            <ScrollToTop />
            <Header />
            <div className="content">
              <Switch>
                <Redirect
                  from="/blog/shift-swapping-is-coming-to-tanda/"
                  to="/posts/shift-swapping-has-arrived/"
                />
                <Redirect
                  from="/blog/what-s-new-in-tanda-september-2018/"
                  to="/posts/whats-new-in-tanda-september-2018/"
                />
                <Redirect
                  from="/blog/what-s-new-in-tanda-july-2018/"
                  to="/posts/whats-new-in-tanda-july-2018/"
                />
                <Redirect from="/blog" to="/" />
                <Route exact path="/" component={Homepage} />
                <Route exact path="/about" component={AboutPage} />
                <Route path="/posts/:slug/" component={SinglePostPage} />
                <Route path="/blog/:slug/" component={SinglePostPage} />
                <Route path="/category/:slug/:id" component={CategoryPage} />
                <Route path="/conference/" component={EventsPage} />
                <Route path="/search/:slug" component={SearchResultsPage} />
                <Route path="/search/" component={SearchResultsPage} />
                <Route path="/privacy-policy" component={PrivacyPolicyPage} />
                <Route
                  path="/cookie-statement"
                  component={CookieStatementPage}
                />

                <Route path="" component={NotFound} />
              </Switch>
            </div>
            <Footer />
          </div>
        </BrowserRouter>
      </React.Fragment>
    );
  }
}

export default App;
