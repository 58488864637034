import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

import './PrivacyPolicyPage.css';
export default class PrivacyPolicyPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Container id="privacyPolicyPage">
          <Row>
            <Col lg={{ size: '8', offset: 2 }}>
              <p className="h3 font-poppins mb-0">Privacy Policy</p>
              <p className="sub font-poppins mb-5">
                Last updated: 7 September 2018
              </p>
              <div className="body-text font-work-sans">
                <p>
                  The Workforce Success website (powered by Tanda) ensures that
                  your private information remains safe. This Privacy Policy
                  statement applies to Workforce Success
                  (www.workforcesuccess.com), all of its content, and our
                  subscription service, including email marketing services and
                  badge notifications via the Tanda Mobile App. This Privacy
                  Policy page states website visitors how we collect, use, and
                  disclose your personal data.
                </p>
                <p className="h4 font-poppins mb-1">
                  I. Information Collection and Use
                </p>
                <p>
                  You are free to explore our website without providing any
                  personal information. For the sake of this page, “personal
                  information” refers to any information you voluntarily submit
                  to us and identifies you, including but not limited to your
                  name, email address, and company/organisation. Navigational
                  information is also included in the information we collect,
                  such as your IP address, geographical location, browser type,
                  referral source, length of visit, and pages viewed. Personal
                  information also includes data about transactions and
                  subscriptions (both paid and free) you perform through our
                  website, that of which we will store and organise for various
                  analytics purposes.
                </p>

                <p>
                  Although it is not mandatory, at some point while browsing our
                  content, we may require you to provide us certain personal
                  information for a better experience with Workforce Success and
                  all its related services. The information you provide us will
                  be used to contact and identify you.
                </p>

                <p>
                  If you are a Tanda Mobile App user, we will send push
                  notifications from time to time in order to update you about
                  events or promotions. If you no longer wish to receive such
                  promotions, you may turn them off or unsubscribe to our
                  communications.
                </p>

                <p>
                  We may link your information and user activity regarding our
                  content with our analytics software. We do this to improve
                  services and ensure that the content we provide are relevant
                  to your work and/or industry.
                </p>

                <p>
                  By choosing to consume, share, and/or contribute content via
                  Workforce Success, you are agreeing to the collection and
                  usage of your information. We will not use or share your
                  information with anyone except as described in this Privacy
                  Policy page. To view our Cookies Statement,{' '}
                  <NavLink to="/cookie-statement">click here.</NavLink>
                </p>
                <p className="h4 font-poppins mb-1">II. Log Data</p>
                <p>
                  Whenever you visit our website, we collect information your
                  browser sends us. This information is referred to as ‘Log
                  Data’ and may include information about your computer
                  hardware, software, IP address, browser type, domain names,
                  internet service provider (ISP), the files viewed on our site,
                  the time and date of your visit, time spent on those pages,
                  your operating system, and other statistics.
                </p>
                <p className="h4 font-poppins mb-1">III. Service Provide</p>
                <p className="mb-0">We may employ third party companies to:</p>
                <p className="mb-0">
                  Host and facilitate our website and services
                </p>
                <p className="mb-0">
                  Provide marketing and advertising services on our behalf
                </p>
                <p>
                  Assist us in analysing how our website and content are being
                  used
                </p>

                <p>
                  These third parties have access to your personal information.
                  However, they are obligated to never disclose or use your
                  information for any other purposes aside from optimising our
                  website and related services.
                </p>

                <p>
                  Workforce Success will never sell your personal information to
                  anyone.
                </p>
                <p className="h4 font-poppins mb-1">IV. Security</p>
                <p>
                  We value your security over the personal information you
                  provide us. We will never sell your data to any third party.
                  We will not use your personal information for any activities
                  outside of our website and services.
                </p>
                <p className="h4 font-poppins mb-1">V. Links to Other Sites</p>
                <p>
                  Our website and services, including communication materials,
                  may contain links to other sites. If you click on any third
                  party link, you will be redirected to a different site not
                  operated by Workforce Success. In doing so, you visiting their
                  websites will have different Privacy Policy and Cookie
                  Statements. Workforce Success does not have any control over,
                  and assumes no responsibility over the content, practices, and
                  personal information you provide to third party links
                  redirected outside of our website and services.
                </p>
                <p className="h4 font-poppins mb-1">
                  VI. Changes to our Privacy Policy
                </p>
                <p>
                  We will update this Privacy Policy statement from time to
                  time. Should significant changes be made, we will send you a
                  notice via email or app notification, whichever is applicable.
                  While we will be responsible in sending you major changes in
                  our Privacy Policy statement, we encourage that you review
                  this page periodically. Older versions of this Privacy Policy
                  will also be archived and available for your view upon
                  request.
                </p>
                <p className="h4 font-poppins mb-1">VII. Contact Us</p>
                <p>
                  If you have any questions about our Privacy Policy &amp;
                  Cookies Statement or about how we treat your personal
                  information, please email us at [*EMAIL*] or send an
                  old-fashioned snail mail to:
                </p>
                <p className="mb-0">EPI Capital Pty Ltd</p>
                <p className="mb-0">Lvl 1/490 Adelaide St.,</p>
                <p className="mb-0">Brisbane, QLD</p>
                <p className="mb-0">Australia 4000</p>
                <p className="mb-0">Attn: Workforce Success Team</p>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
