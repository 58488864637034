import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import './EventSpeakers.css';
import './EventSpeakers_m.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';

let speakers = [
  {
    name: 'Steve Baxter',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2018/11/steve-baxter.jpg',
    title: 'Tech entrepreneur and investor',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'twitter' },
        url: 'https://twitter.com/sbxr'
      },
      {
        icon: faGlobeAmericas,
        url: 'https://www.stevebaxter.com.au/'
      }
    ]
  },
  {
    name: 'Tracy Angwin',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2019/05/tracy_angwin.jpg',
    title: 'CEO of Australian Payroll Association',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'twitter' },
        url: 'https://twitter.com/aust_payroll'
      },
      {
        icon: { prefix: 'fab', iconName: 'linkedin-in' },
        url: 'https://www.linkedin.com/in/tracyangwin/'
      },
      {
        icon: faGlobeAmericas,
        url: 'https://www.austpayroll.com.au/'
      }
    ]
  },
  {
    name: 'Phillip Di Bella',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2019/05/phillip_di_bella_2.jpg',
    title: 'Executive Chairman, Di Bella Coffee',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'linkedin-in' },
        url:
          'https://www.linkedin.com/in/phillip-di-bella-1556936b/?originalSubdomain=au'
      }
    ]
  },
  {
    name: 'Kate Carnell',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2019/06/kate_carnell.jpg',
    title: 'Australian Small Business and Family Enterprise Ombudsman',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'linkedin-in' },
        url: 'https://www.linkedin.com/in/kate-carnell-24ab4a15'
      },
      {
        icon: { prefix: 'fab', iconName: 'facebook' },
        url: 'https://www.facebook.com/ASBFEO'
      },
      {
        icon: { prefix: 'fab', iconName: 'twitter' },
        url: 'https://twitter.com/ASBFEO'
      }
    ]
  },
  {
    name: 'Amanda Lutvey',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2019/01/amanda-lutvey.png',
    title: 'Founder, Career Culture Lab',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'facebook' },
        url: 'https://www.facebook.com/careerculturelab/'
      },
      {
        icon: { prefix: 'fab', iconName: 'linkedin-in' },
        url: 'https://www.linkedin.com/in/amanda-lutvey-0375733/'
      },
      {
        icon: faGlobeAmericas,
        url: 'https://careerculturelab.com/about/'
      }
    ]
  },
  {
    name: 'Gary Mortimer',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2018/11/gary-mortimer.jpg',
    title: 'Queensland University of Technology',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'twitter' },
        url: 'https://twitter.com/the_grocery_dr?lang=en'
      },
      {
        icon: faGlobeAmericas,
        url: 'https://staff.qut.edu.au/staff/gary.mortimer'
      }
    ]
  },
  {
    name: 'Reianna Vercoe',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2019/04/WSCon-Speakers-Reianna-Vercoe.jpg',
    title: 'Head of Product and Marketing, Aurion',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'twitter' },
        url: 'https://twitter.com/reianna'
      },
      {
        icon: { prefix: 'fab', iconName: 'linkedin-in' },
        url: 'https://www.linkedin.com/in/reianna/?originalSubdomain=au'
      },
      {
        icon: faGlobeAmericas,
        url: 'https://www.aurion.com/'
      }
    ]
  },
  {
    name: 'William Gooderson',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2018/11/william-gooderson.jpg',
    title: 'Leadership expert',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'twitter' },
        url: 'https://twitter.com/WillGooderson'
      },
      {
        icon: { prefix: 'fab', iconName: 'linkedin-in' },
        url: 'https://www.linkedin.com/in/willgooderson/'
      },
      {
        icon: faGlobeAmericas,
        url: 'https://www.williamgooderson.com/'
      }
    ]
  },
  {
    name: 'Marcus Wilson',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2018/11/marcus-wilson-1.jpg',
    title: 'CEO, Surgical Partners',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'twitter' },
        url: 'https://twitter.com/mciwilson'
      },
      {
        icon: { prefix: 'fab', iconName: 'linkedin-in' },
        url: 'https://www.linkedin.com/in/mciwilson/?originalSubdomain=au'
      },
      {
        icon: faGlobeAmericas,
        url: ' https://surgicalpartners.com.au/'
      }
    ]
  },
  {
    name: 'Peta Ellis',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2019/06/peta_ellis.jpg',
    title: 'CEO, River City Labs',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'twitter' },
        url: 'https://twitter.com/ellispeta'
      },
      {
        icon: { prefix: 'fab', iconName: 'linkedin-in' },
        url: 'https://www.linkedin.com/in/petaellis'
      },
      {
        icon: faGlobeAmericas,
        url: 'https://twitter.com/RiverCityLabs'
      }
    ]
  },
  {
    name: 'Michael Ellis',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2018/12/WSCon-Speakers_Michael-Elis.png',
    title: 'Culture Strategist, Pragmatic Thinking',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'twitter' },
        url: 'https://twitter.com/mikeyellis13'
      },
      {
        icon: { prefix: 'fab', iconName: 'linkedin-in' },
        url:
          'https://www.linkedin.com/in/michael-ellis-b02b7826/?originalSubdomain=au'
      }
    ]
  },
  {
    name: 'Heather Smith',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2019/03/Heather_SmithMay2018.jpg',
    title: 'Chartered Accountant, Cloud Solutions Expert',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'twitter' },
        url: 'https://twitter.com/HeatherSmithAU'
      },
      {
        icon: { prefix: 'fab', iconName: 'linkedin-in' },
        url: 'https://www.linkedin.com/in/heathersmithau/?originalSubdomain=au'
      },
      {
        icon: faGlobeAmericas,
        url: 'https://heathersmithsmallbusiness.com/'
      }
    ]
  },
  {
    name: 'Kelly Chard',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2019/04/Kelly_Chard.jpg',
    title: 'Chartered Accountant Director & Founder, GrowthMD',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'twitter' },
        url: 'https://twitter.com/kelly_chard'
      },
      {
        icon: { prefix: 'fab', iconName: 'linkedin-in' },
        url: 'https://www.linkedin.com/in/kellychard/?originalSubdomain=au'
      },
      {
        icon: faGlobeAmericas,
        url: 'https://www.growth-md.com/'
      }
    ]
  },
  {
    name: 'Gary Johnson',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2019/01/gary-johnson.png',
    title: 'Executive Chef, Australian Leisure and Hospitality Group',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'linkedin-in' },
        url:
          'https://www.linkedin.com/in/gary-johnson-6b695911/?originalSubdomain=au'
      }
    ]
  },
  {
    name: 'Tracey Johnson',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2019/05/tracey_johnson.jpg',
    title: 'CEO of Inala Primary Care',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'twitter' },
        url: 'https://twitter.com/Januarygirlie'
      },
      {
        icon: { prefix: 'fab', iconName: 'linkedin-in' },
        url: 'https://www.linkedin.com/in/atraceyjohnson/?originalSubdomain=au'
      },
      {
        icon: faGlobeAmericas,
        url: 'http://inalaprimarycare.org.au/'
      }
    ]
  },
  {
    name: 'Paul Rifkin',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2019/04/Paul_Rifkin_2.jpg',
    title: 'Chefpaulrifkin Consulting',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'instagram' },
        url: 'https://www.instagram.com/chefpaulrifkin/'
      },
      {
        icon: faGlobeAmericas,
        url: 'https://www.chefpaulrifkin.com.au/'
      }
    ]
  },
  // {
  //   name: 'Trent Twomey',
  //   profile:
  //     'http://wsadmin.wpengine.com/wp-content/uploads/2019/04/Trent-Twomey-cropped.jpg',
  //   title: 'Senior National Vice President, The Pharmacy Guild of Australia',
  //   social: [
  //     {
  //       icon: { prefix: 'fab', iconName: 'linkedin-in' },
  //       url:
  //         'https://www.linkedin.com/in/trent-twomey-06372886/?originalSubdomain=au'
  //     },
  //     {
  //       icon: faGlobeAmericas,
  //       url: 'https://www.guild.org.au/'
  //     }
  //   ]
  // },
  {
    name: 'Chris Owen',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2019/07/Chris-Owen.jpg',
    title: 'Executive Director, Owen Pharmacy Group',
    social: [
      // {
      //   icon: { prefix: 'fab', iconName: 'linkedin-in' },
      //   url:
      //     'https://www.linkedin.com/in/trent-twomey-06372886/?originalSubdomain=au'
      // },
      // {
      //   icon: faGlobeAmericas,
      //   url: 'https://www.guild.org.au/'
      // }
    ]
  },
  {
    name: 'Therese Lardner',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2019/04/WSCon-Speakers_Therese-Lardner_new.jpg',
    title: 'Leadership Coach and Psychologist',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'linkedin-in' },
        url: 'https://www.linkedin.com/in/thereselardner/'
      }
    ]
  },
  {
    name: 'Travis White',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2019/05/trav_white.jpg',
    title: 'Founder of Neighbourhood (NBH)',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'instagram' },
        url: 'https://www.instagram.com/travwhite/'
      },
      {
        icon: faGlobeAmericas,
        url: 'https://www.nbh.co'
      }
    ]
  },
  {
    name: 'Narelle Dickinson',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2019/05/narelle_dickinson.png',
    title: 'Senior Consultant Psychologist, Chandler Macleod People Insights',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'linkedin-in' },
        url: 'https://www.linkedin.com/in/narelledickinson/'
      },
      {
        icon: faGlobeAmericas,
        url:
          'https://unleashing.chandlermacleod.com/home-splash/people-insights/'
      }
    ]
  },
  {
    name: 'Elliot Spirrett',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2019/05/elliot_spirrett_2.png',
    title: 'Ecosystem Partner Manager, Xero',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'twitter' },
        url: 'https://twitter.com/elliotspirrett1'
      },
      {
        icon: faGlobeAmericas,
        url: 'https://www.xero.com/au/'
      }
    ]
  },
  {
    name: 'Alan Ismaj',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2019/06/alan_ismaj.jpg',
    title: 'Sales Manager, Flare',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'linkedin-in' },
        url: 'https://www.linkedin.com/in/alanismaj'
      },
      {
        icon: faGlobeAmericas,
        url: 'https://www.flarehr.com/'
      }
    ]
  },
  {
    name: 'Nick Braban',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2019/07/Nick_Braban.jpg',
    title: 'General Manager, Celissa',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'linkedin-in' },
        url: 'https://www.linkedin.com/in/nickbraban/?originalSubdomain=au'
      }
    ]
  },
  {
    name: 'Jason Daniels',
    profile:
      'http://wsadmin.wpengine.com/wp-content/uploads/2019/07/JasonDaniels_square.jpg',
    title: 'Director, Faktored Pty Ltd',
    social: [
      {
        icon: { prefix: 'fab', iconName: 'linkedin-in' },
        url: 'https://www.linkedin.com/in/jasondanielsco?originalSubdomain=au'
      }
    ]
  }
];
export default class EventSpeakers extends Component {
  socialList = (speaker, size) => {
    let items = [];
    for (let i = 0; i < size; i++) {
      items.push(
        <a
          key={i}
          href={speaker.social[i].url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon icon={speaker.social[i].icon} />
        </a>
      );
    }
    return <div>{items}</div>;
  };

  speakersGrid = () => {
    const speakerLists = speakers.map((speaker, i) => (
      <Col
        key={i}
        // xs={{ size: 8, offset: 2 }}
        // sm={{ size: 6, offset: 0 }}
        xs="6"
        md="4"
        lg="3"
      >
        <div className="speakers__imgContainer">
          <img src={speaker.profile} alt={speaker.name} />
          <div className="speakers__social">
            {this.socialList(speaker, speaker.social.length)}
          </div>
        </div>
        <p className="speakers__name">{speaker.name}</p>
        <p className="speakers__speakerTitle">{speaker.title}</p>
      </Col>
    ));

    return <Row className="mt-5">{speakerLists}</Row>;
  };

  render() {
    return (
      <React.Fragment>
        {/* <div className="banner">
          <Container className="about">
            <Row>
              <Col lg="5" className="my-auto">
                <p className="about__title">
                  <span className="about__titleHighlight">
                    Workforce Success
                  </span>
                </p>
                <p className="about__title">
                  <span className="about__titleHighlight">Conference 2019</span>{' '}
                </p>
              </Col>
              <Col lg="7">
                <p className="about__desc">
                  The conference aims to gather the doers of the world in a day
                  of learning and professional development opportunities through
                  plenary, breakout, and panel discussions. These opportunities
                  allow participants and sponsors to connect and share their
                  insights and best practices.
                </p>
              </Col>
            </Row>
          </Container>
        </div> */}
        <Container id="speakers" className="speakers">
          <Row className="mb-5">
            <Col
              sm="12"
              md={{ size: 8, offset: 2 }}
              lg={{ size: 6, offset: 3 }}
            >
              <p className="speakers__title">2019 Speakers Lineup</p>
              {/* <p className="speakers__subtitle">
                We have a diverse group of speakers representing different
                sectors, generations, and techniques in what makes a successful
                team in the shift industry.
              </p>
              <a href="mailto:janine@tanda.co?subject=Workforce%20Success%20Conference%20Nominate%20a%20Speaker">
                <Button
                  outline
                  color="primary"
                  className="speakers__btn font-poppins"
                >
                  Nominate a speaker
                </Button>
              </a> */}
            </Col>
          </Row>
          {this.speakersGrid()}
        </Container>
      </React.Fragment>
    );
  }
}
