import React, { Component } from 'react';

export default class CategoryPagePagination extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pageNo: 0
    };
  }
  componentDidMount() {
    this.setState({
      pageNo: this.props.currentPageNo
    });
  }
  pageNoClick = pageNo => {
    this.setState({ pageNo: pageNo }, () => {
      this.props.pager(this.state.pageNo);
    });
  };

  prevClick = () => {
    let currentPageNo = this.state.pageNo;
    if (currentPageNo && currentPageNo !== 0) {
      currentPageNo -= 1;
      this.setState({ pageNo: currentPageNo }, () => {
        this.props.pager(this.state.pageNo);
      });
    }
  };

  nextClick = () => {
    let currentPageNo = this.state.pageNo;
    let postChunkLength = this.props.postArr.length - 1;

    if (currentPageNo !== postChunkLength) {
      currentPageNo += 1;
      this.setState({ pageNo: currentPageNo }, () => {
        this.props.pager(this.state.pageNo);
      });
    }
  };
  createPagination = postsArr => {
    let { pageNo } = this.state;
    return postsArr.map((group, index) => (
      <button
        key={index}
        className={`mx-1 pagination-btn ${index === pageNo && 'active'}`}
        id={index}
        onClick={() => this.pageNoClick(index)}
      >
        {index + 1}
      </button>
    ));
  };
  render() {
    if (this.props.postArr && this.props.postArr.length > 1) {
      return (
        <React.Fragment>
          <button
            className="pagination-btn mx-1"
            onClick={this.prevClick}
            id="test"
          >
            PREV
          </button>
          {this.createPagination(this.props.postArr)}

          <button className="pagination-btn mx-1" onClick={this.nextClick}>
            NEXT
          </button>
        </React.Fragment>
      );
    } else {
      return <React.Fragment />;
    }
  }
}
