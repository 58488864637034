import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import './EventSubscribeBox.css';
import './EventSubscribeBox_m.css';
import SubscribeButton from '.././SubscribeButton/SubscribeButton';
export default class EventSubscribeBox extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="subscribe-bottom">
          <Container>
            <Row>
              <Col lg="7">
                <p className="subscribe-bottom__title">
                  Let’s create{' '}
                  <span className="color-secondary">
                    a great experience in our workplace
                  </span>{' '}
                  and build a{' '}
                  <span className="color-secondary">
                    global workforce success community
                  </span>{' '}
                  together.
                </p>
                <p className="subscribe-bottom__subtitle">
                  Be part of the discussion at the largest workforce success
                  conference.
                </p>
              </Col>
            </Row>
            <Row>
              <Col lg="6">
                <SubscribeButton text="SUBSCRIBE NOW" />
              </Col>
              <Col lg="6">
                <p className="subscribe-bottom__contact mb-0">
                  Any questions? Send it through at
                </p>
                <p className="subscribe-bottom__contact">
                  <a href="mailto:janine@tanda.co?subject=Workforce%20Success%20Conference%20Inquiry">
                    janine@tanda.co
                  </a>
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    );
  }
}
