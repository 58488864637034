import React, { Component } from 'react';

export default class AboutPillars extends Component {
  render() {
    const items = this.props.list.map((el, i) => (
      <li key={i}>{this.props.list[i]}</li>
    ));

    return (
      <React.Fragment>
        <div className="about__pillarCircle">
          <p>{this.props.number}</p>
        </div>
        <div className="mt-3">
          <p className="bold">{this.props.title}</p>
          <p>{this.props.desc}</p>
          <ul className="about__pillarList">{items}</ul>
        </div>
      </React.Fragment>
    );
  }
}
