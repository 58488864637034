import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import config from '../.././config.json';
// Import Style
import './Homepage.css';

// Components
import FeaturedPosts from '../../components/FeaturedPosts/FeaturedPosts';
import SubscribeBox from '../../components/SubscribeBox/SubscribeBox';
import PostsByCategoryDiv from '../../components/PostsByCategoryDiv/PostsByCategoryDiv';
import SubscribeBoxFullWitdth from '../../components/SubscribeBoxFullWitdth/SubscribeBoxFullWitdth';
import SubscribeBoxFooter from '../../components/SubscribeBoxFooter/SubscribeBoxFooter';
import HeroBannerCarousel from '../../components/HeroBannerCarousel/HeroBannerCarousel';
export default class Homepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      latestPosts: [],
      loading: true
    };
  }
  componentWillMount() {
    axios
      .all([
        axios.get(
          config.databaseUrl + 'wp-json/wp/v2/posts/?per_page=3&_embed'
        ),
        axios.get(config.databaseUrl + 'wp-json/wp/v2/categories')
      ])
      .then(
        axios.spread((latestPosts, categories) => {
          this.setState({
            latestPosts: latestPosts.data,
            categories: categories.data,
            loading: false
          });
        })
      )
      .catch(err => console.log(err));
  }
  render() {
    if (!this.state.loading) {
      return (
        <React.Fragment>
          <Helmet>
            <title>Workforce Success</title>
            <link rel="canonical" href="https://www.workforcesuccess.com/" />
            <meta
              name="description"
              content="Workforce Success is the ultimate mastery of work for the service of others. We are a resource platform aimed to help every organisation invest in human capital and leverage people to grow their business."
            />
            {/* Open Graph */}
            <meta property="og:site_name" content="Workforce Success" />
            <meta property="og:title" content="Workforce Success" />
            <meta
              property="og:description"
              content="Workforce Success is the ultimate mastery of work for the service of others. We are a resource platform aimed to help every organisation invest in human capital and leverage people to grow their business."
            />
            <meta
              property="og:image"
              content="http://wsadmin.wpengine.com/wp-content/uploads/2018/10/workforsuccess_logo.png"
            />
            <meta
              property="og:url"
              content="https://www.workforcesuccess.com/"
            />
            <meta property="fb:app_id" content="198162717746029" />
            <meta property="og:type" content="website" />
            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@WSchampions" />
            <meta name="twitter:title" content="Workforce Success" />
            <meta
              name="twitter:description"
              content="Workforce Success is the ultimate mastery of work for the service of others. We are a resource platform aimed to help every organisation invest in human capital and leverage people to grow their business."
            />
            <meta
              name="twitter:image"
              content="http://wsadmin.wpengine.com/wp-content/uploads/2018/10/workforsuccess_logo.png"
            />
          </Helmet>
          <HeroBannerCarousel />
          <Container className="home-body-container">
            <section id="hotAndTrending">
              <Row className="pb-4">
                <Col>
                  <p className="h3 section-title font-weight-bold mb-0">
                    LATEST
                  </p>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col lg="8">
                  <FeaturedPosts posts={this.state.latestPosts} />
                </Col>
                <Col lg="4">
                  <Row>
                    <Col lg="12">
                      <SubscribeBox />
                      {/* <div className="most-popular mt-5">
                          <p className="h3 font-weight-bold mb-4">RECOMMENDED</p>
                          <ArticleMostPopular
                            bgcolor="bg-rp"
                            category="Productivity hacks"
                            title="Get Ahead In Your Career By Asking These Questions"
                            url="#"
                          />
                          <ArticleMostPopular
                            bgcolor="bg-tt"
                            category="heads up"
                            title="Get Ahead In Your Career By Asking These Questions"
                            url="#"
                          />
                          <ArticleMostPopular
                            bgcolor="bg-ub"
                            category="Global first"
                            title="25 Creative Restaurant Marketing Ideas To Get More Customers"
                            url="#"
                          />
                        </div> */}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <PostsByCategoryDiv
                id={this.state.categories['1'].id}
                name={this.state.categories['1'].name}
                slug={this.state.categories['1'].slug}
              />
              <PostsByCategoryDiv
                id={this.state.categories['5'].id}
                name={this.state.categories['5'].name}
                slug={this.state.categories['5'].slug}
              />
              <PostsByCategoryDiv
                id={this.state.categories['2'].id}
                name={this.state.categories['2'].name}
                slug={this.state.categories['2'].slug}
              />
              <PostsByCategoryDiv
                id={this.state.categories['3'].id}
                name={this.state.categories['3'].name}
                slug={this.state.categories['3'].slug}
              />
              <Row className="categories-section d-xl-flex d-lg-flex d-md-flex d-sm-none d-none mt-5r">
                <Col>
                  <SubscribeBoxFullWitdth />
                </Col>
              </Row>
              <PostsByCategoryDiv
                id={this.state.categories['0'].id}
                name={this.state.categories['0'].name}
                slug={this.state.categories['0'].slug}
              />
              <PostsByCategoryDiv
                id={this.state.categories['4'].id}
                name={this.state.categories['4'].name}
                slug={this.state.categories['4'].slug}
              />
              <Row className="pb-xl-4 pb-lg-4 pb-md-4 pb-sm-0 pb-0 categories-section mt-5r">
                <Col>
                  <SubscribeBoxFooter />
                </Col>
              </Row>
            </section>
          </Container>
        </React.Fragment>
      );
    } else {
      return (
        <div className="h-100vh d-flex align-items-center justify-content-center">
          <p className="text-center">Loading...</p>
        </div>
      );
    }
  }
}
