import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import './EventSponsors.css';
import './EventSponsors_m.css';
// import sideKicker from '../.././assets/sidekicker.svg';
// import workplaceByFacebook from '../.././assets/workplace-by-facebook.svg';
import aurion from '../.././assets/aurion.svg';

export default class EventSponsors extends Component {
  render() {
    return (
      <React.Fragment>
        <Row id="eventSponsor">
          <Col id="sponsors" lg="12" className="sponsor-bg__desc">
            <p className="sponsor-bg__title"> 2019 Sponsors</p>
            <p className="sponsor-bg__subtitle mb-5">
              <span className="underline">Make your mark.</span> Disrupt,
              innovate, & take people further by showcasing your brand in the{' '}
              <span className="underline">
                largest gathering for workforce success.
              </span>
            </p>
          </Col>
          {/* <Col className="sponsor__bg__button__col" lg="12">
            <a href="mailto:janine@tanda.co?subject=Workforce%20Success%20Conference%20Exhibitor%20Inquiry&body=Hi!%0A%0AI'm%20interested%20to%20know%20more%20about%20the%20sponsorship%20opportunities%20and%20how%20to%20become%20an%20exhibitor%20in%20the%20Workforce%20Success%20Conference%202019.">
              <Button color="primary" className="sponsor-bg__btn">
                BECOME A SPONSOR
              </Button>
            </a>
          </Col> */}
          <Col xs="12" className="sponsor-bg__images mt-2">
            <Row>
              <Col sm="6" lg={{ size: 4, offset: 2 }}>
                <a
                  href="https://www.aurion.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={aurion} alt={'aurion'} />
                </a>
              </Col>
              <Col sm="6" lg="4">
                <a
                  href="https://www.xero.com/au/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="https://d3l1s0c8npowa8.cloudfront.net/wp-content/uploads/20181112124130/xero.png"
                    // id="sponsor__sideKicker__logo"
                    alt={'xero'}
                  />
                </a>
              </Col>
              <Col sm="6" lg={{ size: 2, offset: 1 }}>
                <a
                  href="https://www.smartwfm.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="http://wsadmin.wpengine.com/wp-content/uploads/2019/04/Smart_WFM_Logo_COL.png"
                    alt={'wfm'}
                  />
                </a>
              </Col>
              <Col sm="6" lg="2">
                <a
                  href="https://www.flarehr.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="http://wsadmin.wpengine.com/wp-content/uploads/2019/05/flare.png"
                    alt={'bdo'}
                  />
                </a>
              </Col>
              <Col sm="6" lg="2">
                <a
                  href="https://www.bdo.com.au/en-au/home"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="http://wsadmin.wpengine.com/wp-content/uploads/2019/05/bdo.png"
                    alt={'bdo'}
                  />
                </a>
              </Col>
              <Col sm="6" lg={{ size: 2, offset: 0 }}>
                <a
                  href="https://www.soulorigin.com.au"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="http://wsadmin.wpengine.com/wp-content/uploads/2019/07/soul_origin.png"
                    alt={'soul origin'}
                  />
                </a>
              </Col>
              <Col sm="6" lg="2">
                <a
                  href="https://www.silverchef.com.au/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="http://wsadmin.wpengine.com/wp-content/uploads/2019/07/silverchef.png"
                    alt={'silverchef'}
                  />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
