import React from 'react';
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Form,
  FormGroup
} from 'reactstrap';
import './SubscribeBoxFooter.css';

class SubscribeBoxFooter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      emailInputCss: '',
      showTooltip: false,
      inputErrMsg: 'Please enter your email'
    };
    this.emailInput = React.createRef();
  }
  showTooltip = () => {
    this.setState({
      showTooltip: true
    });
  };
  hideTooltip = () => {
    this.setState({
      showTooltip: false
    });
  };
  handleChange = e => {
    this.setState({
      email: e.target.value,
      emailInputCss: ''
    });
  };
  validateEmail = email => {
    //eslint-disable-next-line
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let isValid = re.test(String(email).toLowerCase());
    if (isValid) {
      this.sendProspect(email);
    } else {
      this.setState({
        emailInputCss: 'danger',
        inputErrMsg: 'Please enter a valid email'
      });
    }
  };
  handleSubmit = e => {
    e.preventDefault();
    if (this.emailInput.current.props.value) {
      this.setState({
        emailInputCss: ''
      });
      this.validateEmail(this.emailInput.current.props.value);
    } else {
      this.setState({
        emailInputCss: 'danger',
        inputErrMsg: 'Please enter your email'
      });
    }
  };
  sendProspect = email => {
    let emailObj = {
      name: '',
      email: email,
      source: 'https://www.workforcesuccess.com/'
    };
    fetch('https://hooks.zapier.com/hooks/catch/3349564/ls0pvm/', {
      method: 'POST',
      body: JSON.stringify(emailObj)
    })
      .then(res => {
        console.log(res);
        this.setState({
          emailInputCss: ''
        });
      })
      .catch(err => console.log(err));
  };
  render() {
    return (
      <React.Fragment>
        <div className="subscribe-container-footer d-xl-block d-lg-block d-md-block d-sm-none d-none">
          <p className="text-white text-center font-weight-bold font-work-sans mb-2">
            Don’t miss out!
          </p>
          <p className="text-white font-work-sans mb-0 text-center mb-5">
            <span className="font-weight-bold">Subscribe</span> to our
            newsletter and get <br />{' '}
            <span className="font-weight-bold text-tt">Workforce Success</span>{' '}
            resources straight to your inbox.
          </p>
          <Form onSubmit={this.handleSubmit}>
            <FormGroup className={this.state.emailInputCss}>
              <InputGroup className="mx-auto">
                <div
                  className={`input-div ${
                    this.state.showTooltip ? 'show-tooltip' : 'hide-tooltip'
                  }`}
                >
                  <Input
                    type="text"
                    name="email"
                    placeholder="Enter your email"
                    id="emailInputFooter"
                    onChange={this.handleChange}
                    value={this.state.email}
                    ref={this.emailInput}
                  />
                  <div
                    className="error-marker"
                    onMouseEnter={this.showTooltip}
                    onMouseLeave={this.hideTooltip}
                  >
                    !
                  </div>
                  <div className="tooltip bs-tooltip-bottom">
                    <div className="arrow" />
                    <div className="tooltip-inner font-work-sans">
                      {this.state.inputErrMsg}
                    </div>
                  </div>
                </div>
                <InputGroupAddon addonType="append">
                  <Button
                    type="submit"
                    className="subscribe-btn font-weight-light"
                  >
                    Subscribe
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Form>
        </div>
      </React.Fragment>
    );
  }
}

export default SubscribeBoxFooter;
