import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
// import HeaderBg from './../../assets/about-header-bg.png';
import aboutImage1 from './../../assets/about-image1.png';
import aboutImage2 from './../../assets/about-image2.png';

import AboutPillars from './../../components/AboutPillars/AboutPillars';
import NominateBox from './../../components/NominateBox/NominateBox';

import './AboutPage.css';

export default class AboutPage extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="aboutBg">
          <div className="about">
            <div className="aboutBg__firstRow">
              <Container>
                <Row className="about__firstRow">
                  <Col className="pt-4" lg="5">
                    <p className="about__highlight">
                      The Ultimate Mastery of Work for the Service of Others
                    </p>
                    <div className="about__desc mt-5">
                      <p className="bold">What is Workforce Success?</p>
                      <p>
                        What is Workforce Success? When everyone in a workforce
                        reaches their full potential, then so does the business.
                        Simply put, managers who invest in human capital make it
                        easier for their business to grow and succeed.
                      </p>
                    </div>
                  </Col>
                  <Col lg={{ size: 6, offset: 1 }}>
                    <img src={aboutImage2} alt="aboutImage1" width="100%" />
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="aboutBg__secondRow">
              <Container>
                <Row className="about__secondRow flex-lg-row flex-md-column-reverse flex-sm-column-reverse flex-xs-column-reverse">
                  <Col lg="5">
                    <img src={aboutImage1} alt="aboutImage2" width="100%" />
                  </Col>
                  <Col className="pt-3" lg={{ size: 6, offset: 1 }}>
                    <p className="about__highlight">Our Mission & Vision</p>
                    <div className="about__desc mt-5">
                      <p>
                        Tanda grew from 4 undergraduates in a university dorm to
                        120+ employees in 4 continents. And they did it through
                        pure investment in human capital.
                      </p>
                      <p className="bold">Mission</p>
                      <p>
                        Our mission is to instill in every business that the key
                        to growth and success is investing in their most
                        important asset: <span className="bold">people.</span>
                      </p>
                      <p className="bold">Vision</p>
                      <p>
                        Our vision is for Workforce Success to become the
                        standard ideology used by every business in their
                        journey to success.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <Container>
              <Row className="about__pillarsRow">
                <Col className="pt-5" lg="7" xs="12">
                  <p className="about__highlight">
                    The Four Pillars of Workforce Success
                  </p>
                </Col>

                <Col lg="8 mt-4" xs="12">
                  <p className="about__desc">
                    A Workforce Success Champion is the embodiment of the four
                    pillars of Workforce Success. Every WS Champion is
                    open-minded, driven, empowering, and is the ultimate master
                    of their work.
                  </p>
                </Col>
              </Row>
            </Container>
            <div className="aboutBg__thirdRow">
              <Container>
                <Row>
                  <Col xs="12" sm="12" md="6">
                    <AboutPillars
                      number="1"
                      title="Open-minded"
                      desc="WS Champions understand that everything changes at a rapid pace. They are not afraid to innovate, are quick to adapt to trends (and start them too), and have a habit of disrupting competition."
                      list={[
                        'Adaptive to change',
                        'Aware of new tools and trends',
                        'Compliant to regulations',
                        'Disruptive',
                        'Innovative'
                      ]}
                    />
                  </Col>
                  <Col xs="12" sm="12" md="6">
                    <AboutPillars
                      number="2"
                      title="Empowering"
                      desc="The idea of Workforce Success puts emphasis in being of service to others. Being a WS Champion means the quality of work you put out, the way you present and implement ideas, and how you treat others are able to inspire those who also want to succeed."
                      list={[
                        'A mentor',
                        'Helps others succeed',
                        'Inspires others',
                        'Has good work ethic',
                        'Has a sound moral compass'
                      ]}
                    />
                  </Col>
                </Row>
              </Container>
              <Container>
                <Row className="about__pillarSecondRow">
                  <Col xs="12" sm="12" md="6">
                    <AboutPillars
                      number="3"
                      title="Driven"
                      desc="Every WS Champion is a doer. They get things done by maximising what they are given while being accountable for their responsibilities. They deliver work quickly but ensures that they improve their output, too."
                      list={[
                        'Works samrter, not harder',
                        'Collaborates with others',
                        'Accountable for responsibilities',
                        'Works quickly',
                        'Iterative'
                      ]}
                    />
                  </Col>
                  <Col xs="12" sm="12" md="6">
                    <AboutPillars
                      number="4"
                      title="The Ultimate Master of Work"
                      desc="To inspire others, a WS Champion needs to be the ultimate master of their work while constantly staying hungry for more. Others look at them to follow suit in terms of knowledge, ambition, and experience."
                      list={[
                        'An expert in their field',
                        'A constant learner',
                        'Immensely experienced',
                        'Shares their knowledge',
                        'Superb customer/client relationship skills'
                      ]}
                    />
                  </Col>
                </Row>
              </Container>
            </div>
            <Container>
              <Row style={{ marginTop: '130px', marginBottom: '50px' }}>
                <Col className="about__nominateBoxCol">
                  <NominateBox />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
