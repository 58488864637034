import React from 'react';
import {
  Container,
  Row,
  Col,
  Navbar,
  // NavbarBrand,
  Nav,
  NavItem
} from 'reactstrap';
import axios from 'axios';
import config from '../.././config.json';
import { NavLink, withRouter } from 'react-router-dom';
import ConferenceBanner from '../../components/ConferenceBanner/ConferenceBanner';

// Import Style
import './Header.css';

// WS Logo
import wsLogo from '../.././assets/ws-main-logo.svg';
import wsConfLogo from '../.././assets/wscon_2019.svg';
import wsConfDarkSmall from '../.././assets/wscon_2019_small.svg';
import wsConfDarkLogo from '../.././assets/ws-conf-dark-logo.svg';
// import buyTickets from '../.././assets/buy-tickets.svg';

// Components
// import SubscribeButton from '.././SubscribeButton/SubscribeButton';
// import CategoryPage from '../../modules/CategoryPage/CategoryPage';

class HeaderContainer extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      atConference: false,
      isOpen: false,
      modal: false,
      email: '',
      name: '',
      categories: [],
      isLoading: true,
      navLeftScroll: 0,
      searchIsOpen: false,
      seartInputVal: '',
      headerOnTop: true
      // isDoneScrolling: false
    };
    this.leftScroll = React.createRef();
    this.rightScroll = React.createRef();
    this.scrollableNav = React.createRef();
    this.catNavUl = React.createRef();
    this.predictiveSearch = React.createRef();
    this.blogLink = React.createRef();
  }

  componentWillMount() {
    axios
      .get(config.databaseUrl + 'wp-json/wp/v2/categories')
      .then(response => {
        this.setState(
          {
            categories: response.data,
            isLoading: false
          },
          this.setConfState(
            this.props.location.pathname.match(/\/conference/gi)
          )
        );
      })
      .catch(error => {
        // handle error
        console.log(error);
      });
  }
  componentDidMount() {
    // window.addEventListener('scroll', this.hideCatNav);
    window.addEventListener('scroll', this.checkIfScrolledToTheTop);
  }
  componentWillUnmount() {
    // window.removeEventListener('scroll', this.hideCatNav);
    window.removeEventListener('scroll', this.checkIfScrolledToTheTop);
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.setConfState(this.props.location.pathname.match(/\/conference/gi));
      this.checkIfScrolledToTheTop();
    }
  }
  setConfState = confState => {
    let atConference = confState ? true : false;
    this.setState({
      atConference: atConference
    });
  };
  // hideCatNav = () => {
  // const pageOffset = window.pageYOffset;
  // console.log('current', pageOffset);
  // console.log('test', window.pageYOffset);
  // if (pageOffset <= 30) {
  //   this.setState({
  //     scrollClass: ''
  //   });
  // } else {
  //   this.setState({
  //     scrollClass: 'show'
  //   });
  // }
  // };
  handleCatNavScrollLeft = () => {
    this.catNavUl.current.scrollBy({
      left: -150,
      behavior: 'smooth'
    });
  };
  handleCatNavScrollRight = () => {
    this.catNavUl.current.scrollBy({
      left: 150,
      behavior: 'smooth'
    });
  };

  handleCatNavs = () => {
    if (
      this.catNavUl.current.scrollWidth - this.catNavUl.current.clientWidth ===
      this.catNavUl.current.scrollLeft
    ) {
      this.rightScroll.current.style.opacity = 0.4;
    } else {
      this.rightScroll.current.style.opacity = 1;
    }
    if (this.catNavUl.current.scrollLeft === 0) {
      this.leftScroll.current.style.opacity = 0.4;
    } else {
      this.leftScroll.current.style.opacity = 1;
    }
  };

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  checkIfScrolledToTheTop = () => {
    const pageOffset = window.pageYOffset;
    let element = document.getElementById('wsNavbar');
    let navbarBrand = document.getElementById('wsLogo');
    if (this.state.atConference === true) {
      if (pageOffset >= 30) {
        // if (!/\bscrolled-down\b/g.test(element.className)) {
        let name = 'ws-scrolled-down';
        let arr = element.className.split(' ');
        if (arr.indexOf(name) === -1) {
          element.className += ' ' + name;
        }
        element.className = element.className.replace(/\bpy-4\b/g, '');
        navbarBrand.src =
          window.innerWidth > 991 ? wsConfDarkLogo : wsConfDarkSmall;
      } else {
        element.className = element.className.replace(
          /\bws-scrolled-down\b/g,
          ''
        );
        let name2 = 'py-4';
        let arr = element.className.split(' ');
        if (arr.indexOf(name2) === -1) {
          element.className += ' ' + name2;
        }
        navbarBrand.src = wsConfLogo;
      }
    }
  };
  handleSearchInputChange = e => {
    this.setState({
      seartInputVal: e.target.value
    });
  };
  handleKeypress = e => {
    if (e.target.value) {
      if (e.key === 'Enter') {
        this.props.history.push('search/' + e.target.value);
      }
    }
  };
  handleSearchClick = () => {
    let searchEl = this.predictiveSearch.current;
    let blogLinkEl = this.blogLink.current;

    if (searchEl.classList) {
      searchEl.classList.toggle('open-search');
    } else {
      // For IE9
      var classes = searchEl.className.split(' ');
      var i = classes.indexOf('open-search');

      if (i >= 0) classes.splice(i, 1);
      else classes.push('open-search');
      searchEl.className = classes.join(' ');
    }

    if (blogLinkEl.classList) {
      blogLinkEl.classList.toggle('d-none');
    } else {
      // For IE9
      var classesTwo = blogLinkEl.className.split(' ');
      var x = classesTwo.indexOf('d-none');

      if (x >= 0) classesTwo.splice(x, 1);
      else classesTwo.push('d-none');
      blogLinkEl.className = classesTwo.join(' ');
    }
  };
  createCategoryNavs = categories => {
    return (
      <ul
        className="d-flex justify-content-between"
        ref={this.catNavUl}
        onScroll={this.handleCatNavs}
      >
        <li>
          <NavLink to={`/category/${categories[1].slug}/${categories[1].id}`}>
            {categories[1].name}
          </NavLink>
        </li>
        <li>
          <NavLink to={`/category/${categories[5].slug}/${categories[5].id}`}>
            {categories[5].name}
          </NavLink>
        </li>
        <li>
          <NavLink to={`/category/${categories[2].slug}/${categories[2].id}`}>
            {categories[2].name}
          </NavLink>
        </li>
        <li>
          <NavLink to={`/category/${categories[3].slug}/${categories[3].id}`}>
            {categories[3].name}
          </NavLink>
        </li>
        <li>
          <NavLink to={`/category/${categories[0].slug}/${categories[0].id}`}>
            {categories[0].name}
          </NavLink>
        </li>
        <li>
          <NavLink to={`/category/${categories[4].slug}/${categories[4].id}`}>
            {categories[4].name}
          </NavLink>
        </li>
      </ul>
    );
  };

  conference = () => {
    if (window.location.pathname !== '/conference') {
      return <ConferenceBanner />;
    }
  };

  scrollSection = event => {
    let element = event.target.attributes.getNamedItem('data-scroll').value;
    let top = document.getElementById(element).offsetTop;
    window.scrollTo({
      behavior: 'smooth',
      top: top - (element === 'competition' ? 50 : 120)
    });
  };

  render() {
    //Main Header
    if (!this.state.isLoading) {
      return (
        <React.Fragment>
          <header
            className={this.state.atConference ? 'fixed-top' : ''}
            id="headerNav"
          >
            <Navbar
              light
              expand="lg"
              id="wsNavbar"
              className={this.state.atConference ? 'py-4' : 'bg-white'}
            >
              <Container className="justify-content-center">
                <Row className="w-100">
                  <Col
                    xs="12"
                    className={`d-flex justify-content-between align-items-center`}
                  >
                    <NavLink
                      to={this.state.atConference ? '/conference' : '/'}
                      className="navbar-brand"
                    >
                      <img
                        src={this.state.atConference ? wsConfLogo : wsLogo}
                        alt={'wslogo'}
                        id="wsLogo"
                      />
                    </NavLink>
                    <Nav className="ml-auto" navbar>
                      {this.state.atConference ? (
                        <React.Fragment>
                          <li className="nav-item mr-4" ref={this.blogLink}>
                            <NavLink
                              to="/about/"
                              target="_blank"
                              className="blog-link font-poppins"
                            >
                              ABOUT
                            </NavLink>
                          </li>
                          <li className="nav-item mr-4">
                            <span
                              onClick={this.scrollSection}
                              data-scroll="speakers"
                              className="blog-link font-poppins"
                            >
                              SPEAKERS
                            </span>
                          </li>
                          <li className="nav-item mr-4">
                            <span
                              onClick={this.scrollSection}
                              data-scroll="agenda"
                              className="blog-link font-poppins"
                            >
                              AGENDA
                            </span>
                          </li>
                          <li className="nav-item mr-4">
                            <span
                              onClick={this.scrollSection}
                              data-scroll="sponsors"
                              className="blog-link font-poppins"
                            >
                              SPONSORS
                            </span>
                          </li>
                          {/* <li className="nav-item mr-4">
                            <span
                              onClick={this.scrollSection}
                              data-scroll="competition"
                              className="blog-link font-poppins"
                            >
                              CASE COMPETITION
                            </span>
                          </li> */}
                          <NavItem className="mr-4">
                            <div
                              className="ws-predictive-search"
                              ref={this.predictiveSearch}
                            >
                              <input
                                type="text"
                                placeholder="SEARCH..."
                                onChange={this.handleSearchInputChange}
                                onKeyPress={this.handleKeypress}
                              />
                              <span
                                className="search-icon"
                                onClick={this.handleSearchClick}
                              >
                                <span className="circle" />
                                <span className="handle" />
                              </span>
                            </div>
                          </NavItem>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <NavItem className="font-work-sans top-nav-link mr-4">
                            <NavLink to="/about">ABOUT</NavLink>
                          </NavItem>
                          <NavItem className="font-work-sans top-nav-link mr-4">
                            <NavLink to="/conference">
                              WS CONFERENCE 2019
                            </NavLink>
                          </NavItem>
                        </React.Fragment>
                      )}

                      {/* <NavItem>
                        {this.state.atConference ? (
                          <a
                            href="https://www.eventbrite.com.au/e/workforce-success-conference-2019-tickets-52300325682?ref=ebtn"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img src={buyTickets} alt="tickets" />
                          </a>
                        ) : (
                          <SubscribeButton />
                        )}
                      </NavItem> */}
                    </Nav>
                  </Col>
                </Row>
              </Container>
            </Navbar>
            <section
              id="wsCategoriesNavbar"
              className={`${this.state.atConference ? 'd-none' : 'd-block'}`}
            >
              <nav ref={this.scrollableNav} id="catNav">
                <Container>
                  <Row>
                    <Col
                      sm="1"
                      xs="1"
                      className="d-lg-none d-md-none cat-scroller-col"
                    >
                      <button
                        className="cat-nav-scoller scroll-left"
                        type="button"
                        ref={this.leftScroll}
                        onClick={this.handleCatNavScrollLeft}
                      >
                        {'<'}
                      </button>
                    </Col>
                    <Col lg="12" md="12" sm="10" xs="10">
                      <div className="ws-cat-nav-links">
                        {this.createCategoryNavs(this.state.categories)}
                      </div>
                    </Col>
                    <Col
                      sm="1"
                      xs="1"
                      className="d-lg-none d-md-none cat-scroller-col"
                    >
                      {' '}
                      <button
                        className="cat-nav-scoller scroll-right"
                        type="button"
                        ref={this.rightScroll}
                        onClick={this.handleCatNavScrollRight}
                      >
                        {'>'}
                      </button>
                    </Col>
                  </Row>
                </Container>
              </nav>
            </section>
          </header>
          {this.conference()}
        </React.Fragment>
      );
    } else {
      return <React.Fragment />;
    }
  }
}

export default withRouter(HeaderContainer);
// Header.contextTypes = {
//   router: PropTypes.object,
// };

// Header.propTypes = {
//   toggleAddPost: PropTypes.func.isRequired,
//   switchLanguage: PropTypes.func.isRequired,
//   intl: PropTypes.object.isRequired,
// };
