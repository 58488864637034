import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import voca from 'voca';
import axios from 'axios';
import _ from 'lodash';
import config from '../.././config.json';
import readingTime from 'reading-time';
// Global Components
import ArticleCard from '.././ArticleCard/ArticleCard';
// Import Style
import './PostsByCategoryDiv.css';
export default class PostsByCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      articleCards: '',
      loading: true,
      screenSM: false,
      screenMD: false,
      screenLG: false
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.onScreenResize);
    this.onScreenResize();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onScreenResize);
  }
  onScreenResize = () => {
    let currentscreenSM = window.innerWidth <= 767.98;
    let currentscreenMD = window.innerWidth > 767.98;
    if (currentscreenSM !== this.state.screenSM) {
      this.setState({
        screenSM: currentscreenSM,
        screenMD: false,
        screenLG: false
      });
    }
    if (currentscreenMD !== this.state.screenMD) {
      this.setState({
        screenSM: false,
        screenMD: currentscreenMD,
        screenLG: false
      });
    }
  };
  componentWillMount() {
    axios
      .get(
        `${config.databaseUrl}wp-json/wp/v2/posts?categories=${
          this.props.id
        }&per_page=3&_embed`
      )
      .then(response => {
        this.setState({
          posts: response.data,
          loading: response.data.length ? false : true
        });
      })
      .catch(error => console.log(error));
  }
  createArticleCards = posts => {
    let bgColorArr = ['bg-ub', 'bg-rp', 'bg-tt'];

    if (posts.length !== 0 && posts.length >= 2) {
      let featuredImageFile;

      let articleCardsEl = posts.map((post, index) => {
        // console.log(post._embedded['wp:featuredmedia'][0].media_details.sizes);
        let featuredMedia = post._embedded['wp:featuredmedia'];
        if (post._embedded['wp:featuredmedia']) {
          if (featuredMedia[0].media_details.sizes.medium_large) {
            featuredImageFile =
              featuredMedia[0].media_details.sizes.medium_large.source_url;
          } else if (featuredMedia[0].media_details.sizes.full) {
            featuredImageFile =
              featuredMedia[0].media_details.sizes.full.source_url;
          } else {
            featuredImageFile = '';
          }
        } else {
          featuredImageFile = '';
        }

        return (
          <Col
            xl="4"
            lg="4"
            md="4"
            sm="12"
            xs="12"
            className={`${
              this.state.screenSM ? 'long no-author-img' : 'normal'
            } mb-xl-7 mb-lg-7 mb-md-7 mb-sm-4 mb-4 no-preview no-category`}
            key={index}
          >
            <ArticleCard
              featuredImg={featuredImageFile}
              title={post.title.rendered}
              authorImg={
                post._embedded.author[0].avatar_urls[
                  Object.keys(post._embedded.author[0].avatar_urls)[2]
                ]
              }
              authorName={post._embedded.author[0].name}
              datePosted={post.date}
              minutesToRead={readingTime(post.content.rendered).text}
              bgcolor={bgColorArr[_.random(0, 2)]}
              url={post.slug}
            />
          </Col>
        );
      });

      return articleCardsEl;
    } else if (posts.length !== 0 && posts.length === 1) {
      let featuredImageFile;
      if (
        posts[0]._embedded['wp:featuredmedia'][0].media_details.sizes
          .medium_large
      ) {
        featuredImageFile =
          posts[0]._embedded['wp:featuredmedia'][0].media_details.sizes
            .medium_large.source_url;
      } else if (
        posts[0]._embedded['wp:featuredmedia'][0].media_details.sizes.full
      ) {
        featuredImageFile =
          posts[0]._embedded['wp:featuredmedia'][0].media_details.sizes.full
            .source_url;
      } else {
        featuredImageFile = '';
      }
      return (
        <Col
          xl="4"
          lg="4"
          md="4"
          sm="12"
          xs="12"
          className={`${
            this.state.screenSM ? 'long no-author-img' : 'normal'
          } mb-xl-7 mb-lg-7 mb-md-7 mb-sm-4 mb-4 no-preview no-category`}
        >
          <ArticleCard
            featuredImg={featuredImageFile}
            title={posts[0].title.rendered}
            authorImg={
              posts[0]._embedded.author[0].avatar_urls[
                Object.keys(posts[0]._embedded.author[0].avatar_urls)[2]
              ]
            }
            authorName={posts[0]._embedded.author[0].name}
            datePosted={posts[0].date}
            minutesToRead={readingTime(posts.content[0].rendered).text}
            bgcolor={bgColorArr[_.random(0, 2)]}
            url={posts[0].slug}
          />
        </Col>
      );
    }
  };
  render() {
    if (!this.state.loading && this.state.posts.length !== 0) {
      return (
        <React.Fragment>
          <Row className="pb-4 categories-section mt-5r">
            <Col xl="6" lg="6" md="6" sm="9" xs="9">
              <p className="h3 section-title font-weight-bold mb-0">
                {voca.upperCase(this.props.name)}
              </p>
            </Col>
            <Col
              xl="6"
              lg="6"
              md="6"
              sm="3"
              xs="3"
              className={`${
                this.state.posts.length >= 3 ? 'd-flex' : 'd-none'
              } align-items-center justify-content-end text-right`}
            >
              <NavLink
                to={'/category/' + this.props.slug + '/' + this.props.id}
                className="small font-weight-light letter-spacing-1 more-category-link"
              >
                {`MORE${
                  this.state.screenSM
                    ? ''
                    : ' ON ' + voca.upperCase(this.props.name)
                }`}
                {/* {`MORE ON ${voca.upperCase(this.props.name)}`} */}
                <div className="arrow-long a-right pull-right d-inline-flex align-items-center">
                  <div className="h-line" />
                  <i className="right" />
                </div>
              </NavLink>
            </Col>
          </Row>
          <Row className="category-cards">
            {this.createArticleCards(this.state.posts)}
          </Row>
        </React.Fragment>
      );
    } else {
      return <React.Fragment />;
    }
  }
}
