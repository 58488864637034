import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import { Container } from 'reactstrap';
import './EventsPage.css';
import './EventsPage_m.css';
import EventSubscribeBox from './../../components/EventSubscribeBox/EventSubscribeBox';
// import EventCountdown from './../../components/EventCountdown/EventCountdown';
import EventFeaturedBlog from './../../components/EventFeaturedBlog/EventFeaturedBlog';
import EventSponsors from './../../components/EventSponsors/EventSponsors';
import EventTestimonials from './../../components/EventTestimonials/EventTestimonials';
import EventHeroHeader from './../../components/EventHeroHeader/EventHeroHeader';
import EventSpeakers from './../../components/EventSpeakers/EventSpeakers';
import EventAbout from './../../components/EventAbout/EventAbout';
// import EventCompetition from '../../components/EventCompetition/EventCompetition';
import EventAgendas from '../../components/EventAgendas/EventAgendas';

export default class EventsPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>WS Conference</title>
          <link rel="canonical" href="https://www.workforcesuccess.com/" />
          {/* Google / Search Engine Tags */}
          <meta itemprop="name" content="Workforce Success" />
          <meta
            itemprop="description"
            content="Workforce Success is the ultimate mastery of work for the service of others. We are a resource platform aimed to help every organisation invest in human capital and leverage people to grow their business."
          />
          <meta
            itemprop="image"
            content="http://wsadmin.wpengine.com/wp-content/uploads/2019/03/ws-conference-2109.jpg"
          />

          {/* Facebook Meta Tags */}
          <meta
            property="og:url"
            content="https://www.workforcesuccess.com/conference"
          />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Workforce Success" />
          <meta
            property="og:description"
            content="Workforce Success is the ultimate mastery of work for the service of others. We are a resource platform aimed to help every organisation invest in human capital and leverage people to grow their business."
          />
          <meta
            property="og:image"
            content="http://wsadmin.wpengine.com/wp-content/uploads/2019/03/ws-conference-2109.jpg"
          />

          {/* Twitter Meta Tags */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Workforce Success" />
          <meta
            name="twitter:description"
            content="Workforce Success is the ultimate mastery of work for the service of others. We are a resource platform aimed to help every organisation invest in human capital and leverage people to grow their business."
          />
          <meta
            name="twitter:image"
            content="http://wsadmin.wpengine.com/wp-content/uploads/2019/03/ws-conference-2109.jpg"
          />
        </Helmet>
        <section id="eventsPage">
          <EventHeroHeader />
          <div className="top-bg-img">
            <EventSpeakers />
            {/* <EventCountdown /> */}
            <EventAgendas />
          </div>
          <div className="sponsor-bg">
            <Container>
              <EventSponsors />
            </Container>
            {/* <EventCompetition /> */}
            <EventAbout />
            <EventTestimonials />
            <Container>
              <EventFeaturedBlog />
            </Container>
          </div>
          <EventSubscribeBox />
        </section>
      </React.Fragment>
    );
  }
}
