import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import _ from 'lodash';
import readingTime from 'reading-time';

// Components
import ArticleCard from '../ArticleCard/ArticleCard';

export default class FeaturedPosts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenSM: false,
      screenMD: false,
      screenLG: false
    };
  }
  componentDidMount() {
    window.addEventListener('resize', this.onScreenResize);
    this.onScreenResize();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onScreenResize);
  }
  onScreenResize = () => {
    let currentscreenSM = window.innerWidth <= 767.98;
    let currentscreenMD = window.innerWidth > 767.98;
    if (currentscreenSM !== this.state.screenSM) {
      this.setState({
        screenSM: currentscreenSM,
        screenMD: false,
        screenLG: false
      });
    }
    if (currentscreenMD !== this.state.screenMD) {
      this.setState({
        screenSM: false,
        screenMD: currentscreenMD,
        screenLG: false
      });
    }
  };

  setFeaturedImg = (posts, index, type) => {
    // return new Promise((resolve, reject) => {
    let featuredImgFull;
    let featuredImgSM;
    let featuredImgMD;
    let featuredImgLG;
    if (posts[index]._embedded['wp:featuredmedia']) {
      featuredImgFull = posts[index]._embedded['wp:featuredmedia'][0]
        .media_details.sizes.full
        ? posts[index]._embedded['wp:featuredmedia'][0].media_details.sizes.full
            .source_url
        : '';
      featuredImgLG = posts[index]._embedded['wp:featuredmedia'][0]
        .media_details.sizes['large']
        ? posts[index]._embedded['wp:featuredmedia'][0].media_details.sizes[
            'large'
          ].source_url
        : featuredImgFull;
      featuredImgMD = posts[index]._embedded['wp:featuredmedia'][0]
        .media_details.sizes['medium_large']
        ? posts[index]._embedded['wp:featuredmedia'][0].media_details.sizes[
            'medium_large'
          ].source_url
        : featuredImgFull;

      featuredImgSM = posts[index]._embedded['wp:featuredmedia'][0]
        .media_details.sizes['medium']
        ? posts[index]._embedded['wp:featuredmedia'][0].media_details.sizes[
            'medium'
          ].source_url
        : featuredImgFull;
    } else {
      featuredImgFull = '';
      featuredImgLG = '';
      featuredImgMD = '';
      featuredImgSM = '';
    }

    if (type === 'small') {
      if (this.state.screenSM) {
        return featuredImgSM;
      }
      if (this.state.screenMD) {
        return featuredImgMD;
      }
    }
    if (type === 'big') {
      if (this.state.screenSM) {
        return featuredImgMD;
      }
      if (this.state.screenMD) {
        return featuredImgLG;
      }
    }
  };

  featuredPosts = posts => {
    if (posts.length !== 0 && posts.length >= 3) {
      let bgColorArr = ['bg-ub', 'bg-rp', 'bg-tt'];
      let randomNum = _.random(0, 2);

      // console.log(
      //   '0',
      //   posts[0]._embedded['wp:featuredmedia'][0].media_details.sizes
      // );
      // console.log(
      //   '1',
      //   posts[1]._embedded['wp:featuredmedia'][0].media_details.sizes
      // );
      // console.log(
      //   '2',
      //   posts[2]._embedded['wp:featuredmedia'][0].media_details.sizes
      // );

      return (
        <Row>
          <Col
            xl="12"
            lg="12"
            md="12"
            sm="12"
            xs="12"
            className="featured mb-5"
          >
            <ArticleCard
              featuredImg={this.setFeaturedImg(posts, 0, 'big')}
              category={posts[0]._embedded['wp:term'][0][0].name}
              title={posts[0].title.rendered}
              content={posts[0].excerpt.rendered}
              authorImg={
                posts[0]._embedded.author[0].avatar_urls[
                  Object.keys(posts[0]._embedded.author[0].avatar_urls)[2]
                ]
              }
              authorName={posts[0]._embedded.author[0].name}
              datePosted={posts[0].date}
              minutesToRead={readingTime(posts[0].content.rendered).text}
              bgcolor={bgColorArr[randomNum]}
              url={posts[0].slug}
              type="featured"
            />
          </Col>

          <Col
            xl="6"
            lg="6"
            md="6"
            sm="12"
            xs="12"
            className={`${
              this.state.screenSM ? 'long no-preview no-author-img' : 'normal'
            } mb-xl-7 mb-lg-7 mb-md-7 mb-sm-4 mb-4`}
          >
            <ArticleCard
              featuredImg={this.setFeaturedImg(posts, 1, 'small')}
              category={posts[1]._embedded['wp:term'][0][0].name}
              title={posts[1].title.rendered}
              content={posts[1].excerpt.rendered}
              authorImg={
                posts[1]._embedded.author[0].avatar_urls[
                  Object.keys(posts[1]._embedded.author[0].avatar_urls)[2]
                ]
              }
              authorName={posts[1]._embedded.author[0].name}
              datePosted={posts[1].date}
              minutesToRead={readingTime(posts[1].content.rendered).text}
              bgcolor={bgColorArr[randomNum]}
              url={posts[1].slug}
              type="normal"
            />
          </Col>
          <Col
            xl="6"
            lg="6"
            md="6"
            sm="12"
            xs="12"
            className={`${
              this.state.screenSM ? 'long no-preview no-author-img' : 'normal'
            } mb-xl-7 mb-lg-7 mb-md-7 mb-sm-4 mb-4`}
          >
            <ArticleCard
              featuredImg={this.setFeaturedImg(posts, 2, 'small')}
              category={posts[2]._embedded['wp:term'][0][0].name}
              title={posts[2].title.rendered}
              content={posts[2].excerpt.rendered}
              authorImg={
                posts[2]._embedded.author[0].avatar_urls[
                  Object.keys(posts[2]._embedded.author[0].avatar_urls)[2]
                ]
              }
              authorName={posts[2]._embedded.author[0].name}
              datePosted={posts[2].date}
              minutesToRead={readingTime(posts[2].content.rendered).text}
              bgcolor={bgColorArr[randomNum]}
              url={posts[2].slug}
              type="normal"
            />
          </Col>
        </Row>
      );
    } else {
      return <React.Fragment />;
    }
  };
  render() {
    return (
      <React.Fragment>{this.featuredPosts(this.props.posts)}</React.Fragment>
    );
  }
}
