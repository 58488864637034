import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import './EventAgendas.css';
export default class EventAgendas extends Component {
  render() {
    return (
      <div id="agenda" className="agendas mt-5 container">
        <p className="agendas__title">Event Programme</p>
        {/* <a
          href="https://whova.com/web/workf_201907/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button color="primary">SHOW FULL AGENDA</Button>
        </a> */}

        <div className="agendas__table">
          <Row>
            <Col md="3" lg="2" className="agendas__time">
              <p>8:00 - 8:30 am</p>
            </Col>
            <Col md="9" lg="10">
              <strong className="singles">
                Registration and Exhibition Hall Open{' '}
              </strong>
            </Col>

            <Col md="3" lg="2" className="agendas__time ">
              <p>8:30 - 9:00 am</p>
            </Col>
            <Col md="9" lg="10" className="background">
              <strong className="singles">
                The Tanda Platform and Workforce Success
              </strong>
            </Col>

            <Col md="3" lg="2" className="agendas__time">
              <p>9:00 - 9:30 am</p>
            </Col>
            <Col md="9" lg="10">
              <div className="singles">
                <strong>On Governance / Compliance </strong>
                <p>Kate Carnell, ASBFEO Ombudsman</p>
              </div>
            </Col>

            <Col md="3" lg="2" className="agendas__time ">
              <p>9:30 - 10:10 am</p>
            </Col>
            <Col md="9" lg="10" className="background">
              <div className="singles">
                <strong>On Workplace Management: Building on the Best</strong>
                <p>Steve Baxter, Tech Entrepreneur</p>
              </div>
            </Col>

            <Col xs="6" md="3" lg="2" className="agendas__time agendas__break">
              <p>10:10 - 10:40 am</p>
            </Col>
            <Col xs="6" md="9" lg="10" className=" agendas__break">
              <strong>Morning Break</strong>
            </Col>

            <Col md={{ size: 9, offset: 3 }}>
              <Row className="agendas__largetag">
                <Col md="6">
                  <h2>Main Plenary</h2>
                </Col>
                <Col md="6">
                  <h2>Industry-Specific Sessions</h2>
                </Col>
              </Row>
            </Col>

            <Col md="3" lg="2" className="agendas__time background__time">
              <p>10:40 - 11:20 am</p>
            </Col>
            <Col md="9" lg="10">
              <Row className="background divide">
                <Col md="6" className="agendas__name">
                  <div>
                    <small className="agendas__smalltag">Main Plenary</small>
                    <strong>Seeing the World as Customers Do</strong>
                    <p>Travis White, Founder, Neighbourhood</p>
                  </div>
                </Col>
                <Col md="6" className="agendas__name">
                  <div>
                    <small className="agendas__smalltag">
                      Industry-Specific Sessions
                    </small>
                    <strong>
                      Overcoming Barriers to Technology Adoption in Healthcare
                    </strong>
                    <p>Marcus Wilson, CEO Surgical Partners</p>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md="3" lg="2" className="agendas__time background__time">
              <p>11:20 - 12:00 pm</p>
            </Col>
            <Col md="9" lg="10">
              <Row>
                <Col md="6" className="agendas__name">
                  <div>
                    <small className="agendas__smalltag">Main Plenary</small>
                    <strong>
                      Creating a Personal Brand: Being Your Best you
                    </strong>
                    <p>Phil Di Bella, Executive Chairman Di Bella Coffee</p>
                  </div>
                </Col>
                <Col md="6" className="agendas__name">
                  <div>
                    <small className="agendas__smalltag">
                      Industry-Specific Sessions
                    </small>
                    <strong>Panel on Hospitality</strong>
                    <p>
                      Paul Rifkin, Gary Johnson, Nick Braban
                      <span>Moderator: Phil Johnson</span>
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md="3" lg="2" className="agendas__time background__time">
              <p>12:00 - 12:40 pm</p>
            </Col>
            <Col md="9" lg="10">
              <Row className="background">
                <Col md="6" className="agendas__name">
                  <div>
                    <small className="agendas__smalltag">Main Plenary</small>
                    <strong>
                      Personal Networks and Micro-Tribes: Opening Up
                      Opportunities for your Career
                    </strong>
                    <p>Peta Ellis, CEO River Labs</p>
                  </div>
                </Col>
                <Col md="6" className="agendas__name">
                  <div>
                    <small className="agendas__smalltag">
                      Industry-Specific Sessions
                    </small>
                    <strong>Panel: Leading Healthcare Excellence</strong>
                    <p>
                      Marcus Wilson, Kelly Chard, Tracey Johnson
                      <span>Moderator: Robert Dickson</span>
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col xs="6" md="3" lg="2" className="agendas__time agendas__break">
              <p>12:40 - 1:30 pm</p>
            </Col>
            <Col xs="6" md="9" lg="10" className=" agendas__break">
              <strong>Lunch Break</strong>
            </Col>

            <Col md="3" lg="2" className="agendas__time background__time">
              <p>1:30 - 2:10 pm</p>
            </Col>
            <Col md="9" lg="10">
              <Row>
                <Col md="6" className="agendas__name">
                  <div>
                    <small className="agendas__smalltag">Main Plenary</small>
                    <strong>Beyond the Bonuses: Engaging your Staff</strong>
                    <p>Mikey Ellis, Pragmatic Thinking</p>
                  </div>
                </Col>
                <Col md="6" className="agendas__name">
                  <div>
                    <small className="agendas__smalltag">
                      Industry-Specific Sessions
                    </small>
                    <strong>
                      Panel: Retail Transformations: What’s in Store?
                    </strong>
                    <p>
                      Chris Owen, Gary Mortimer, Alan Ismaj
                      <span>Moderator: Liam Hukins</span>
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md="3" lg="2" className="agendas__time background__time">
              <p>2:10 - 2:50 pm</p>
            </Col>
            <Col md="9" lg="10">
              <Row className="background">
                <Col md="6" className="agendas__name">
                  <div>
                    <small className="agendas__smalltag">Main Plenary</small>
                    <strong>Building Resilience in the Workplace</strong>
                    <p>Narelle Dickinson, Chandler Macleod People Insights</p>
                  </div>
                </Col>
                <Col md="6" className="agendas__name">
                  <div>
                    <small className="agendas__smalltag">
                      Industry-Specific Sessions
                    </small>
                    <strong>
                      Panel on Change Management: How to Deal with Non-Believers{' '}
                    </strong>
                    <p>
                      Will Gooderson, Therese Lardner
                      <span>Moderator: Rod Schneider</span>
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col xs="6" md="3" lg="2" className="agendas__time agendas__break">
              <p>2:50 - 3:10 pm</p>
            </Col>
            <Col xs="6" md="9" lg="10" className=" agendas__break">
              <strong>Afternoon Break</strong>
            </Col>

            <Col md="3" lg="2" className="agendas__time background__time">
              <p>3:10 - 3:50 pm</p>
            </Col>
            <Col md="9" lg="10">
              <Row>
                <Col md="6" className="agendas__name">
                  <div>
                    <small className="agendas__smalltag">Main Plenary</small>
                    <strong>Differentiate Don’t Demonstrate</strong>
                    <p>Dr. Gary Mortimer, QUT</p>
                  </div>
                </Col>
                <Col md="6" className="agendas__name">
                  <div>
                    <small className="agendas__smalltag">
                      Industry-Specific Sessions
                    </small>
                    <strong>
                      Payroll Panel: Where there’s risk, there’s opportunity{' '}
                    </strong>
                    <p>
                      Elliot Spirret, Reianna Vercoe, Jason Daniels
                      <span>Moderator: Heather Smith</span>
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md="3" lg="2" className="agendas__time background__time">
              <p>3:50 - 4:30 pm</p>
            </Col>
            <Col md="9" lg="10">
              <Row className="background">
                <Col md="6" className="agendas__name">
                  <div>
                    <small className="agendas__smalltag">Main Plenary</small>
                    <strong>
                      Getting Payroll Right: How to Avoid Being the Next
                      Australian Underpayment Scandal
                    </strong>
                    <p>Tracy Angwin, Australian Payroll Association</p>
                  </div>
                </Col>
                <Col md="6" className="agendas__name">
                  <div>
                    <small className="agendas__smalltag">
                      Industry-Specific Sessions
                    </small>
                    <strong>
                      Panel: Culture and Career Development - Is it all smoke &
                      mirrors?{' '}
                    </strong>
                    <p>
                      Amanda Lutvey, Mikey Ellis, Therese Lardner
                      <span>Moderator: Dan Etiel</span>
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col md="3" lg="2" className="agendas__break remove" />
            <Col md="9" lg="10" className=" agendas__break">
              <strong>Closing</strong>
            </Col>

            <Col md="3" lg="2" className="agendas__time">
              <p>4:30 - 5:00 pm</p>
            </Col>
            <Col md="9" lg="10">
              <div className="singles">
                <strong>
                  Beyond Communication: Encouraging Collaboration and Inspiring
                  your Workforce
                </strong>
                <p>Will Gooderson</p>
              </div>
            </Col>

            <Col md="3" lg="2" className="agendas__time ">
              <p>5:00 - 5:15 pm</p>
            </Col>
            <Col md="9" lg="10" className="background">
              <strong className="singles">Closing</strong>
            </Col>

            <Col xs="6" md="3" lg="2" className="agendas__time agendas__break">
              <p>5:15 - 8:00 pm</p>
            </Col>
            <Col xs="6" md="9" lg="10" className=" agendas__break">
              <strong>Socials</strong>
            </Col>
          </Row>
        </div>

        {/* <div>
          <div title="Whova event and conference app" id="whova-agendawidget">
            <p id="whova-loading">Loading...</p>
          </div>
          <Helmet>
            <script
              src="https://whova.com/static/frontend/agenda_webpage/js/embedagenda.js?eid=workf_201907&host=https://whova.com"
              type="text/javascript"
              id="embeded-agenda-script"
            />
          </Helmet>
        </div> */}
      </div>
    );
  }
}
