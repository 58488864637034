import React, { Component } from 'react';
import { Row, Col, Container } from 'reactstrap';
import './EventAbout.css';
import './EventAbout_m.css';
// import ticketCard from './../../assets/ticket-card.svg';
import EventVideos from '../EventVideos/EventVideos';

export default class EventAbout extends Component {
  render() {
    return (
      <React.Fragment>
        <Container className="about about__2018">
          <Row>
            <Col lg="12">
              {/* <a
                href="https://www.youtube.com/watch?v=ky7U2RdEtA4"
                target="_blank"
              >
                <Button
                  outline
                  color="primary"
                  className="about__btn mt-5 mb-5"
                >
                  Watch 2018 highlight reel
                </Button>
              </a> */}
              {/* <Row>
                <Col className="about__conference__title">
                  <p>2018 Beyond Conference</p>
                </Col>
              </Row> */}
              <Row className="mb-5">
                <Col lg="4" className="my-auto">
                  <p className="about__title">
                    2018 Conference
                    <span className="about__titleHighlight__2">
                      Highlights
                    </span>{' '}
                  </p>
                </Col>
                <Col xs="4" lg="3">
                  <p className="about__count">250+</p>
                  <p className="about__countDesc">Attendees</p>
                </Col>
                <Col xs="4" lg="2">
                  <p className="about__count">40</p>
                  <p className="about__countDesc">Speakers</p>
                </Col>
                <Col xs="4" lg="3">
                  <p className="about__count">12</p>
                  <p className="about__countDesc">Breakout Sessions</p>
                </Col>
              </Row>
              <EventVideos />
            </Col>
            {/*
            Video Module
            <Col lg="4">
                    <Row className="about__videoCounter">
                        <Col lg="5" style={{padding: 0}}>
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe className="embed-responsive-item" width="560" height="315" src="https://www.youtube.com/embed/Mmhxni3hKks" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        </Col>
                        <Col lg="7">
                            <p className="about__videoTitle">BEYOND 2018 | Panel: How Technology is…</p>
                            <p className="about__videoTime">01:12 mins</p>
                        </Col>
                    </Row>

                     <Row className="about__videoCounter">
                        <Col lg="5" style={{padding: 0}}>
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe className="embed-responsive-item" width="560" height="315" src="https://www.youtube.com/embed/ecF_t5Gt5XU" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                        </Col>
                        <Col lg="7">
                            <p className="about__videoTitle">BEYOND 2018 | Solving Problems Faster</p>
                            <p className="about__videoTime">01:12 mins</p>
                        </Col>
                    </Row>

                     <Row className="about__videoCounter">
                        <Col lg="5" style={{padding: 0}}>
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowfullscreen></iframe>
                        </div>
                        </Col>
                        <Col lg="7">
                            <p className="about__videoTitle">BEYOND 2018 | Managing Millennials</p>
                            <p className="about__videoTime">01:12 mins</p>
                        </Col>
                    </Row>

                     <Row className="about__videoCounter">
                        <Col lg="5" style={{padding: 0}}>
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowfullscreen></iframe>
                        </div>
                        </Col>
                        <Col lg="7">
                            <p className="about__videoTitle">BEYOND 2018 | Taking Advantage..</p>
                            <p className="about__videoTime">01:12 mins</p>
                        </Col>
                    </Row>

                     <Row className="about__videoCounter">
                        <Col lg="5" style={{padding: 0}}>
                        <div class="embed-responsive embed-responsive-16by9">
                            <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/zpOULjyy-n8?rel=0" allowfullscreen></iframe>
                        </div>
                        </Col>
                        <Col lg="7">
                            <p className="about__videoTitle">BEYOND 2018 | Taking Advantage..</p>
                            <p className="about__videoTime">01:12 mins</p>
                        </Col>
                    </Row>

                  </Col> */}
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
