import React, { Component } from 'react';
// import { Button, Row, Col } from 'reactstrap';
import './EventHeroHeader.css';
import './EventHeroHeader_m.css';
// import headerVideo from "../.././assets/ws-beyond-reel.mp4";
// import buyTickets from '../.././assets/buy-tickets.svg';

/* Import below is Stef's to do */

export default class EventHeroHeader extends Component {
  render() {
    return (
      <React.Fragment>
        {/* Code below is JD's to do */}
        <section id="eventHeroHeader">
          <div className="hero-div">
            <div className="frame__top1" />
            <div className="frame__top2" />
            <div className="frame__bottom1" />
            <div className="frame__bottom2" />
            <div className="hero-bg">
              <video playsInline autoPlay muted loop>
                <source
                  src="https://marketing-assets.tanda.co/wp-content/uploads/20190813152638/WSCON-Web-Highlight.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="bg-filter" />
            <div className="header-text d-flex justify-content-center container">
              <div className="text-div">
                <p className="h2 text-center font-white font-poppins mb-3 mt-5 hero-title">
                  We had a blast!
                </p>
                <p className="text-center font-white font-poppins mb-0 hero-subtitle">
                  Thank you for being part of the Workforce Success community.
                  See you in 2020!
                </p>
                <p className="font-white text-center font-poppins mt-5 mb-3 subtitle">
                  Register your interest and get the latest updates on the next
                  Workforce Conference 2020
                </p>
                <a
                  className="btn-header"
                  href="https://www.tanda.co/wscon2019/"
                >
                  Learn More
                </a>
                {/* <p className="h2 font-white font-poppins mb-0 mt-5 hero-title">
                  workforce
                </p>
                <p className="h2 font-white font-poppins mb-0 hero-title">s
                  success
                </p>
                <p className="h2 font-white font-poppins mb-5 hero-title title-blue">
                  conference 2019
                </p>
                <p className="font-white font-poppins mb-2 subtitle">
                  Building the biggest gathering of frontline businesses in the
                  world.
                </p>
                <p className="font-poppins mb-5 date event">
                  Friday, 26 July 2019 &nbsp; • &nbsp; Hilton Brisbane
                </p> */}
                {/* <Row>
                  <Col lg="2" md="12">
                    <a
                      href="https://www.eventbrite.com.au/e/workforce-success-conference-2019-tickets-52300325682?ref=ebtn"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={buyTickets} alt="tickets" />
                    </a>
                  </Col>
                  <Col lg="2" md="12">
                    <a href="mailto:janine@tanda.co?subject=Workforce%20Success%20Conference%20Exhibitor%20Inquiry&body=Hi!%0A%0AI'm%20interested%20to%20know%20more%20about%20the%20sponsorship%20opportunities%20and%20how%20to%20become%20an%20exhibitor%20in%20the%20Workforce%20Success%20Conference%202019.">
                      <Button
                        outline
                        color="primary"
                        className="ml-4 font-poppins"
                      >
                        BECOME A SPONSOR
                      </Button>
                    </a>
                  </Col>
                </Row> */}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
