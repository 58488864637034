import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';
import { Helmet } from 'react-helmet';
import { Container, Row, Col, Media } from 'reactstrap';
import { animateScroll as scroll } from 'react-scroll';
import moment from 'moment';
import axios from 'axios';
import voca from 'voca';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SinglePostPage.css';
import config from '../.././config.json';
import readingTime from 'reading-time';
// import _ from 'lodash';

// import ArticleCard from '../../components/ArticleCard/ArticleCard';

export default class SinglePostPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      singlePost: undefined,
      uri: props.location.pathname,
      scrollClass: '',
      relatedArticles: undefined,
      timeToRead: '',
      screenXS: false,
      screenSM: false,
      screenMD: false,
      screenLG: false
    };
  }

  componentWillMount() {
    axios
      .get(
        config.databaseUrl +
          'wp-json/wp/v2/posts?slug=' +
          this.props.match.params.slug +
          '&_embed'
      )
      .then(response => {
        this.setState(
          {
            singlePost: response.data,
            timeToRead: readingTime(response.data[0].content.rendered).text
          },
          this.onScreenResize()
        );
        // this.getRelatedArticles(response.data[0].categories[0]);
        //New tab when link inside the content
        // this.scrollToHashElement();
        var content = document.getElementById('singlePostContent');
        var links = content.getElementsByTagName('a');
        var len = links.length;
        for (var i = 0; i < len; i++) {
          links[i].target = '_blank';
        }
        window.addEventListener('load', this.scrollToHashElement());
      })
      .catch(error => console.log(error));
  }
  componentDidMount() {
    window.addEventListener('scroll', this.handleScrollEvent);
    window.addEventListener('resize', this.onScreenResize);

    this.onScreenResize();
  }
  componentWillUnmount() {
    window.removeEventListener('load', this.scrollToHashElement);
    window.removeEventListener('scroll', this.handleScrollEvent);
    window.removeEventListener('resize', this.onScreenResize);
  }
  scrollToHashElement = () => {
    let hashId = voca.slice(this.props.location.hash, 1);
    if (document.getElementById(hashId)) {
      setTimeout(() => {
        document.getElementById(hashId).scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }, 500);
    }
  };
  onScreenResize = () => {
    let currentscreenXS = window.innerWidth <= 575.98;
    let currentscreenSM =
      window.innerWidth > 575.98 && window.innerWidth < 767.98;
    let currentscreenMD =
      window.innerWidth > 767.98 && window.innerWidth < 991.98;
    let currentscreenLG = window.innerWidth >= 991.98;
    if (currentscreenXS !== this.state.screenXS) {
      this.setState({
        screenXS: currentscreenXS,
        screenSM: false,
        screenMD: false,
        screenLG: false
      });
    }
    if (currentscreenSM !== this.state.screenSM) {
      this.setState({
        screenXS: false,
        screenSM: currentscreenSM,
        screenMD: false,
        screenLG: false
      });
    }
    if (currentscreenMD !== this.state.screenMD) {
      this.setState({
        screenXS: false,
        screenSM: false,
        screenMD: currentscreenMD,
        screenLG: false
      });
    }
    if (currentscreenLG !== this.state.screenLG) {
      this.setState({
        screenXS: false,
        screenSM: false,
        screenMD: false,
        screenLG: currentscreenLG
      });
    }
  };
  // getRelatedArticles = category => {
  //   axios
  //     .get(
  //       config.databaseUrl +
  //         `wp-json/wp/v2/posts?categories=${category}&per_page=3&_embed`
  //     )
  //     .then(response => {
  //       // console.log(response.data);
  //       this.setState({
  //         relatedArticles: response.data
  //       });
  //     })
  //     .catch(error => console.log(error));
  // };
  // relatedArticles = () => {
  //   let bgColorArr = ['bg-ub', 'bg-rp', 'bg-tt'];
  //   let randomNum = _.random(0, 2);
  //   if (this.state.relatedArticles !== undefined) {
  //     let articles = this.state.relatedArticles.map((posts, index) => {
  //       let featuredImageFile;
  //       let featuredMedia = posts._embedded['wp:featuredmedia'];
  //       if (posts._embedded['wp:featuredmedia']) {
  //         if (featuredMedia[0].media_details.sizes.medium_large) {
  //           featuredImageFile =
  //             featuredMedia[0].media_details.sizes.medium_large.source_url;
  //         } else {
  //           featuredImageFile = '';
  //         }
  //       } else {
  //         featuredImageFile = '';
  //       }
  //       return (
  //         <Col lg="4" className={'normal'} key={index}>
  //           <ArticleCard
  //             featuredImg={featuredImageFile}
  //             category={posts._embedded['wp:term'][0][0].name}
  //             title={posts.title.rendered}
  //             content={posts.excerpt.rendered}
  //             authorImg={
  //               posts._embedded.author[0].avatar_urls[
  //                 Object.keys(posts._embedded.author[0].avatar_urls)[2]
  //               ]
  //             }
  //             authorName={posts._embedded.author[0].name}
  //             datePosted={posts.date}
  //             minutesToRead="9"
  //             bgcolor={bgColorArr[randomNum]}
  //             url={posts.slug}
  //             type="featured"
  //           />
  //         </Col>
  //       );
  //     });
  //     return articles;
  //   }
  // };

  handleScrollEvent = () => {
    const pageOffset = window.pageYOffset;
    if (pageOffset <= 30) {
      this.setState({
        scrollClass: ''
      });
    } else {
      this.setState({
        scrollClass: 'show'
      });
    }
  };
  handleScrollTop = () => {
    scroll.scrollToTop({
      duration: 500
      // delay: 100,
      // smooth: 'easeInOutQuint',
      // containerId: 'singlePostPage',
      // offset: 500 // Scrolls to element + 50 pixels down the page
    });
  };
  render() {
    let featuredImageFile;
    if (this.state.singlePost === undefined) {
      return (
        <div className="h-100vh d-flex align-items-center justify-content-center">
          <p className="text-center">Loading...</p>
        </div>
      );
    } else {
      let postExcerpt = ReactHtmlParser(
        this.state.singlePost[0].excerpt.rendered
      );
      // let featuredMedia;
      let featuredImgFull;
      let featuredImgSM;
      let featuredImgMD;
      let featuredImgLG;
      if (this.state.singlePost[0]._embedded['wp:featuredmedia']) {
        featuredImgFull = this.state.singlePost[0]._embedded[
          'wp:featuredmedia'
        ][0].media_details.sizes.full
          ? this.state.singlePost[0]._embedded['wp:featuredmedia'][0]
              .media_details.sizes.full.source_url
          : '';
        featuredImgLG = this.state.singlePost[0]._embedded[
          'wp:featuredmedia'
        ][0].media_details.sizes['twentyseventeen-featured-image']
          ? this.state.singlePost[0]._embedded['wp:featuredmedia'][0]
              .media_details.sizes['twentyseventeen-featured-image'].source_url
          : featuredImgFull;

        featuredImgMD = this.state.singlePost[0]._embedded[
          'wp:featuredmedia'
        ][0].media_details.sizes['large']
          ? this.state.singlePost[0]._embedded['wp:featuredmedia'][0]
              .media_details.sizes['large'].source_url
          : featuredImgFull;

        featuredImgSM = this.state.singlePost[0]._embedded[
          'wp:featuredmedia'
        ][0].media_details.sizes['medium_large']
          ? this.state.singlePost[0]._embedded['wp:featuredmedia'][0]
              .media_details.sizes['medium_large'].source_url
          : featuredImgFull;
      } else {
        featuredImgFull = '';
        featuredImgLG = '';
        featuredImgMD = '';
        featuredImgSM = '';
      }
      if (this.state.screenLG) {
        featuredImageFile = featuredImgLG;
      }
      if (this.state.screenMD) {
        featuredImageFile = featuredImgMD;
      }
      if (this.state.screenSM) {
        featuredImageFile = featuredImgSM;
      }
      if (this.state.screenXS) {
        featuredImageFile = featuredImgSM;
      }

      return (
        <React.Fragment>
          <Helmet>
            <meta charSet="utf-8" />
            <title>
              {ReactHtmlParser(this.state.singlePost[0].title.rendered)}
            </title>
            <link rel="canonical" href="https://www.workforcesuccess.com/" />
            {/* Open Graph */}
            <meta property="og:site_name" content="Workforce Success" />
            <meta
              name="description"
              content={
                postExcerpt['0'] === 'undefined'
                  ? postExcerpt['0'].props.children['0']
                  : ''
              }
            />
            <meta
              property="og:title"
              content={ReactHtmlParser(this.state.singlePost[0].title.rendered)}
            />
            <meta
              property="og:description"
              content={
                postExcerpt['0'] === 'undefined'
                  ? postExcerpt['0'].props.children['0']
                  : ''
              }
            />
            <meta property="og:image" content={featuredImageFile} />
            <meta
              property="og:url"
              content={`https://www.workforcesuccess.com/posts/${
                this.state.singlePost[0].title.rendered
              }`}
            />
            <meta property="og:type" content="article" />
            <meta property="fb:app_id" content="198162717746029" />
            {/* Twitter */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:site" content="@WSchampions" />
            <meta
              name="twitter:title"
              content={ReactHtmlParser(this.state.singlePost[0].title.rendered)}
            />
            <meta
              name="twitter:description"
              content={
                postExcerpt['0'] === 'undefined'
                  ? postExcerpt['0'].props.children['0']
                  : ''
              }
            />
            <meta name="twitter:image" content={featuredImageFile} />
          </Helmet>
          <Container id="singlePostPage">
            {/* <Row className={this.state.isLoggedIn ? '' : 'd-none'}>
            <Col>
              <div className="top-opts py-5 d-flex justify-content-end">
                <Link
                  to={`posts/${article.cuid}/edit`}
                  className="opt font-work-sans"
                >
                  <FontAwesomeIcon size="1x" icon="pen" className="mr-2" />
                  Edit
                </Link>
              </div>
            </Col>
          </Row> */}

            <Row className="mb-5 mt-5">
              <Col sm="12" md="12">
                <div
                  className="img-container"
                  style={{
                    backgroundImage: featuredImageFile
                      ? `url(${featuredImageFile})`
                      : ''
                  }}
                />
              </Col>
            </Row>
            <Row className="row-title">
              <Col>
                <h3 className="title">
                  {ReactHtmlParser(this.state.singlePost[0].title.rendered)}
                </h3>
              </Col>
            </Row>
            <Row className="row-categories">
              <Col lg="12">
                <p className="subheading text-center">
                  In{' '}
                  <NavLink
                    to={
                      '/category/' +
                      this.state.singlePost[0]._embedded['wp:term'][0][0].slug +
                      '/' +
                      this.state.singlePost[0]._embedded['wp:term'][0][0].id
                    }
                  >
                    {this.state.singlePost[0]._embedded['wp:term'][0][0].name}
                  </NavLink>{' '}
                  by {/* <a href=""> */}{' '}
                  {this.state.singlePost[0]._embedded.author[0].name}
                  {/* </a>{" "} */} |{' '}
                  {moment(this.state.singlePost[0].date).format('LL')}
                  {' ∙ '}
                  {this.state.timeToRead}
                </p>
              </Col>
            </Row>
            {/* <Row className="mb-5">
            <Col sm="12" md={{ size: 6, offset: 3 }}>
              <img src={'/' + featured_img} alt="" className="img-fluid" />
            </Col>
          </Row> */}
            <Row>
              <Col id="singlePostContent" lg="12">
                <div className="content-div">
                  {ReactHtmlParser(this.state.singlePost[0].content.rendered)}
                </div>
              </Col>
            </Row>
            <Row id={'authorDivider'}>
              <Col className="mt-5 mb-4" lg="12">
                <span className={'divider'} />
              </Col>
            </Row>
            <Row id={'authorContent'}>
              <Col lg="12">
                <p className={'container mb-3 authorLabel'}>About the author</p>
                <Media className={'container'}>
                  <Media left href="#">
                    <Media
                      className={'media'}
                      object
                      src={
                        this.state.singlePost[0]._embedded.author[0]
                          .avatar_urls[
                          Object.keys(
                            this.state.singlePost[0]._embedded.author[0]
                              .avatar_urls
                          )[2]
                        ]
                      }
                      alt="Generic placeholder image"
                    />
                  </Media>
                  <Media
                    body
                    className={'ml-xl-4 ml-lg-4 ml-md-0 ml-sm-0 ml-0'}
                  >
                    <span className={'mediaName'}>
                      {this.state.singlePost[0]._embedded.author[0].name}
                    </span>
                    <br />
                    {this.state.singlePost[0]._embedded.author[0].description}
                  </Media>
                </Media>
              </Col>
            </Row>
            {/* <Row className={"mt-5"}>
              <Col lg="12">
                <span className={"relatedArticlelabel"}>Related articles</span>
              </Col>
            </Row>
            <Row className={"mt-5"}>{this.relatedArticles()}</Row> */}
            <div
              onClick={this.handleScrollTop}
              className={`scroll-top-div ${this.state.scrollClass}`}
            >
              <p className={`scroll-to-top-btn`}>
                <FontAwesomeIcon size="2x" icon="angle-up" className="mr-3" />
              </p>
            </div>
          </Container>
        </React.Fragment>
      );
    }
  }
}

// SinglePostPage.propTypes = {
//   fetchPost: PropTypes.func.isRequired
// };

// const mapStateToProps = state => ({
//   singlePost: state.singlePost.specificPost
// });

// export default connect(
//   mapStateToProps,
//   { fetchPost }
// )(SinglePostPage);
