import React, { Component } from 'react';
import { Button } from 'reactstrap';
import './NominateBox.css';

export default class NominateBox extends Component {
  render() {
    return (
      <React.Fragment>
        <div className="nominateBox">
          <p className="nominateBox__title">
            Know someone who is the perfect example of a WS Champion?
          </p>
          <p className="nominateBox__desc">Send us an email right away!</p>
          <a href="mailto:janine@tanda.co?subject=Workforce%20Success%20Conference%20Nominate%20a%20Speaker">
            <Button className="nominateBox__btn">Nominate a WS Champion</Button>
          </a>
        </div>
      </React.Fragment>
    );
  }
}
