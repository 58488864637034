import React from 'react';
import './Error404Page.css';
import { Link } from 'react-router-dom';
import { Button, Row, Col } from 'reactstrap';

export function Error404Page(props) {
  return (
    <React.Fragment>
      <div className="NotFoundContainer">
        <div>
          <Row>
            <Col sm="12">
              <p className="error-title">Error 404</p>
            </Col>
          </Row>
          <Row>
            <Col sm="12" className="title">
              <p>
                <span>Lessons often come</span>
              </p>
              <p>
                <span>dressed up as detours</span>
              </p>
              <p>
                <span>and roadblocks.</span>
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm="12" className="error-description">
              <p>The page you were looking for doesn't exist.</p>
              <p>
                You may have mistyped the address or the page may have moved.{' '}
              </p>
            </Col>
          </Row>
          <Row>
            <Col sm="12" className="error-cta-container">
              <Link to="/">
                <Button className="error-cta">Keep Exploring</Button>
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Error404Page;
