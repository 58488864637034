import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { CardBody, Media, Badge } from 'reactstrap';
import moment from 'moment';
import $clamp from 'clamp-js-main';
import ReactHtmlParser from 'react-html-parser';
import './ArticleCard.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
export default class ArticleTitle extends Component {
  constructor(props) {
    super(props);
    this.articleTitle = React.createRef();
    this.articleContent = React.createRef();
  }
  componentDidMount() {
    this.clampText();
  }
  clampText = () => {
    $clamp(this.articleTitle.current, { clamp: 2, useNativeClamp: false });
    $clamp(this.articleContent.current, { clamp: 3, useNativeClamp: false });
    this.setInlineStyle(true);
  };
  setInlineStyle = isDone => {
    if (isDone) {
      this.articleTitle.current.style.display = 'inline';
    }
  };
  editPostRedirection = () => {
    window.location.href = `posts/${this.props.cuid}/edit`;
  };
  render() {
    let article = {
      title: this.props.title ? ReactHtmlParser(this.props.title) : '',
      content: this.props.content ? ReactHtmlParser(this.props.content) : ''
    };
    return (
      <React.Fragment>
        <NavLink
          className="article-card card"
          to={this.props.url ? '/posts/' + this.props.url + '/' : '/'}
        >
          <div className="card-img-div mb-3">
            <div
              className="card-img"
              style={{
                backgroundImage: `url(${
                  this.props.featuredImg ? this.props.featuredImg : ''
                })`
              }}
            />
          </div>
          <CardBody>
            <div className="go-up-hover">
              <div className="badge-div">
                <Badge
                  className={`${this.props.bgcolor ? this.props.bgcolor : ''}`}
                >
                  {this.props.category ? this.props.category : ''}
                </Badge>
              </div>
              {/* {this.setTitleAndContent()} */}
              <div className="card-title-div">
                <p
                  className={`card-title h3 font-weight-bold font-poppins hover-${
                    this.props.bgcolor ? this.props.bgcolor : ''
                  }`}
                  ref={this.articleTitle}
                >
                  {article.title}
                </p>
              </div>
              <p className="card-text font-work-sans" ref={this.articleContent}>
                {article.content
                  ? ReactHtmlParser(article.content[0].props.children[0])
                  : ''}
                {/* <span className="for-seo">{article.content}</span> */}
              </p>
              <Media className="author-bottom d-flex align-items-center">
                <div className="media-left media-middle">
                  <Media
                    object
                    src={this.props.authorImg ? this.props.authorImg : ''}
                    alt="Kitten placeholder image"
                  />
                </div>
                <Media body>
                  <p className="name font-weight-bold mb-0 font-work-sans">
                    {this.props.authorName ? this.props.authorName : ''}
                  </p>
                  <p className="mb-0 font-work-sans date-time">
                    <span className="date">
                      {this.props.datePosted
                        ? moment(this.props.datePosted).format('LL')
                        : ''}
                    </span>
                    <span className="mx-2 font-work-sans mdot">&middot;</span>
                    <span className="mins-to-read font-work-sans">
                      {this.props.minutesToRead ? this.props.minutesToRead : ''}
                    </span>
                  </p>
                </Media>
              </Media>
            </div>
          </CardBody>
          <div className="card-options ml-5">
            <div
              onClick={this.editPostRedirection}
              className="opt font-work-sans"
            >
              <FontAwesomeIcon size="1x" icon="pen" className="mr-2" />
              Edit
            </div>
            {/* <div className="divider mx-3" />
            <div className="opt font-work-sans">
              <FontAwesomeIcon size="1x" icon="archive" className="mr-2" />
              Archive
            </div> */}
          </div>
          <div
            className={`progress-bar ${
              this.props.bgcolor ? this.props.bgcolor : ''
            }`}
          />
        </NavLink>
      </React.Fragment>
    );
  }
}
