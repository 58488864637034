import React from "react";
// import { Link } from 'react-router';
import { Container, Row, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";
// Import Style
import "./Footer.css";
import tandaLogo from "../../assets/tanda-logo.svg";

export function Footer() {
  return (
    <React.Fragment>
      <Container id="footer">
        <Row className="mb-4">
          <Col>
            <span className="fw-border-top" />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col>
            <p className="mb-0 mr-2">POWERED BY</p>
            <a
              href="https://www.tanda.co"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={tandaLogo} alt="Tanda 1# Workforce Success Platform" />
            </a>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="mr-5">
              <p className="mb-0">Copyright 2018. All rights reserved.</p>
            </div>
            <div className="mr-auto">
              <NavLink to={"/privacy-policy"} className="ws-footer-link">
                PRIVACY POLICY
              </NavLink>
              <span>|</span>
              <NavLink to={"/cookie-statement"} className="ws-footer-link">
                COOKIE STATEMENT
              </NavLink>
            </div>

            <div className="ml-auto">
              <a
                href="https://www.instagram.com/workforcesuccess/"
                className="ws-footer-icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={["fab", "instagram"]} />
              </a>
              <a
                href="https://www.facebook.com/workforcesuccess/"
                className="ws-footer-icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={["fab", "facebook-f"]} />
              </a>
              <a
                href="https://www.twitter.com/WSchampions/"
                className="ws-footer-icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={["fab", "twitter"]} />
              </a>
              {/* <a href="#" className="ws-footer-icon">
                <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
              </a> */}
              <a
                href="https://www.youtube.com/channel/UCHGXKstzNkDBZrHRT6nCZFQ"
                className="ws-footer-icon"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={["fab", "youtube"]} />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Footer;
