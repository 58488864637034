import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
// import Slider from 'react-slick';
import './EventTestimonials.css';
// import quotationMark from '../.././assets/quotation-mark.png';
export default class EventTestimonials extends Component {
  render() {
    // var settings = {
    //   dots: true,
    //   infinite: true,
    //   speed: 500,
    //   slidesToShow: 1,
    //   slidesToScroll: 1,
    //   autoplay: true,
    //   autoplaySpeed: 5000
    // };
    return (
      <React.Fragment>
        <Row>
          <Col lg="6" className="padding-0">
            <Row className="testimonial">
              <Col sm="6" lg="8" className="testimonial__bg__1">
                <Row>
                  <Col
                    lg="10"
                    xl="8"
                    className="offset-lg-2 offset-xl-4 testimonial__desc"
                  >
                    <div>
                      <p>
                        It was valuable information and I enjoyed the
                        workshops... I would definitely be going back next year.
                      </p>
                      <p>Jes Stone, Frontline Manager for Domino's</p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                sm="6"
                lg="4"
                className="testimonial__img testimonial__img__1"
              />
              <Col
                sm="6"
                lg="6"
                xl="8"
                className="testimonial__img testimonial__img__2 order-2 order-sm-1"
              />
              <Col
                sm="6"
                lg="6"
                xl="4"
                className="testimonial__bg__2 order-1 order-sm-2"
              >
                <Row>
                  <Col lg="12" className="testimonial__desc">
                    <div>
                      <p>
                        Good content, good price, and well executed. Great for
                        networking.
                      </p>
                      <p>Michael Woodruff Customology</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col lg="6">
            <Row className="testimonial">
              <Col sm="6" lg="6" xl="4" className="testimonial__bg__3">
                <Row>
                  <Col xl="12" className="testimonial__desc">
                    <div>
                      <p>
                        Loved the event. Great shared content and knowledge.
                      </p>
                      <p>Rebecca Laut Xero</p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col
                sm="6"
                lg="6"
                xl="8"
                className="testimonial__img testimonial__img__3"
              />
              <Col
                sm="6"
                lg="4"
                xl="4"
                className="testimonial__img testimonial__img__4 order-2 order-sm-1"
              />
              <Col
                sm="6"
                lg="8"
                xl="8"
                className="testimonial__bg__1 order-1 order-sm-2"
              >
                <Row>
                  <Col lg="10" xl="8" className="testimonial__desc">
                    <div>
                      <p>
                        Thought it was excellent for a first conference and I’m
                        excited to see what it will be like next year.
                      </p>
                      <p>Jade Wellings Guilmartin Group - AAOK Parks</p>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* <Slider {...settings}>
          <div className="testimonials-desc testimonial-1">
          <Container>
          <Row>
                <Col>
                  <div className="border-top" />
                  <p className="body">
                    Loved the event. Great shared content and knowledge
                    <img src={quotationMark} alt=""/>
                  </p>
                  <p className="mt-5">Rebecca Laut, Xero</p>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="testimonials-desc testimonial-2">
            <Container>
              <Row>
                <Col>
                  <div className="border-top" />
                  <p className="body">
                    Thought it was excellent for a first conference and I'm
                    excited to see what it will be like next year.
                    <img src={quotationMark} alt=""/>
                  </p>
                  <p className="mt-5">
                    Jade Wellings, Guilmartin Group - AAOK Parks
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="testimonials-desc testimonial-3">
            <Container>
              <Row>
                <Col>
                  <div className="border-top" />
                  <p className="body">
                    Good content, good price, and well executed. Great for
                    networking
                    <img src={quotationMark} alt=""/>
                  </p>
                  <p className="mt-5">Michael Woodruff, Customology</p>
                </Col>
              </Row>
            </Container>
          </div>
        </Slider> */}
      </React.Fragment>
    );
  }
}
