import React, { Component } from 'react';
import ArticleCard from '../../components/ArticleCard/ArticleCard';
import { Row, Col } from 'reactstrap';
import axios from 'axios';
import config from '../.././config.json';
import _ from 'lodash';
import './EventFeaturedBlog.css';

export default class EventFeaturedBlog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      relatedArticles: undefined
    };
  }
  componentWillMount() {
    this.getBlogs();
  }

  getBlogs = () => {
    axios
      .get(config.databaseUrl + `wp-json/wp/v2/posts?per_page=3&_embed`)
      .then(response => {
        this.setState({
          relatedArticles: response.data
        });
      })
      .catch(error => console.log(error));
  };

  blogs = () => {
    let bgColorArr = ['bg-ub', 'bg-rp', 'bg-tt'];
    let randomNum = _.random(0, 2);
    if (this.state.relatedArticles !== undefined) {
      let articles = this.state.relatedArticles.map((posts, index) => {
        let featuredImageFile;
        let featuredMedia = posts._embedded['wp:featuredmedia'];
        if (posts._embedded['wp:featuredmedia']) {
          if (featuredMedia[0].media_details.sizes.medium_large) {
            featuredImageFile =
              featuredMedia[0].media_details.sizes.medium_large.source_url;
          } else {
            featuredImageFile = '';
          }
        } else {
          featuredImageFile = '';
        }
        return (
          <Col lg="4" className={'normal no-preview no-category'} key={index}>
            <ArticleCard
              featuredImg={featuredImageFile}
              category={posts._embedded['wp:term'][0][0].name}
              title={posts.title.rendered}
              authorImg={
                posts._embedded.author[0].avatar_urls[
                  Object.keys(posts._embedded.author[0].avatar_urls)[2]
                ]
              }
              authorName={posts._embedded.author[0].name}
              datePosted={posts.date}
              minutesToRead="9 min read"
              bgcolor={bgColorArr[randomNum]}
              url={posts.slug}
              type="featured"
            />
          </Col>
        );
      });
      return articles;
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="featured__blog">
          <Row>
            <Col lg="12">
              <span className={'blogRowTitle'}>Read our blog</span>
            </Col>
          </Row>
          <Row className={'mt-5'}>{this.blogs()}</Row>
        </div>
      </React.Fragment>
    );
  }
}
