import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

import "./CookieStatementPage.css";
export default class CookieStatementPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Container id="cookieStatementPage">
          <Row>
            <Col lg={{ size: "8", offset: 2 }}>
              <p className="h3 font-poppins mb-0">Cookies Statement</p>
              <p className="sub font-poppins mb-5">
                Last updated: 7 September 2018
              </p>
              <div className="body-text font-work-sans">
                <p>
                  The Workforce Success website collects cookies whenever you
                  use or visit our website.
                </p>
                <p>
                  A cookie is a small text file stored in your browser or
                  device. Some cookies are essential to bring you better
                  service. Other cookies collect anonymous or personal
                  information that allow us to bring you relevant content.
                </p>

                <p>
                  Some cookies may be automatically deleted when you close your
                  browser. However, other types of cookies will remain on your
                  computer until they are deleted or expire. The latter type of
                  cookies retain your user preferences and improve your browsing
                  experience in our website.
                </p>

                <p>
                  The cookies we collect will also be used to tailor advertising
                  and marketing content to ensure we only show what is relevant
                  to you. Some of these cookies include your IP address, device,
                  browser, and connected email address. We also use cookies for
                  performance and analytics for further improvement of our
                  website. Should you be logged in a social media platform while
                  browsing and/or using Workforce Success, some cookies can
                  identify your visit and collect information about your social
                  media activities in order to improve our service.
                </p>

                <p>
                  By continuing to use the Workforce Success website and its
                  resources, you agree to our use of cookies. You have the
                  choice to limit the usage of cookies stored in your device
                  and/or not to accept our cookies statement. However, blocking
                  cookies might slow down or malfunction some features on our
                  website. Tracking of your information does not also
                  automatically stop when you block or delete cookies.
                  Additionally, rejecting cookies does not guarantee that you
                  will not receive advertising and marketing communications
                  based on your browsing activities on Workforce Success.
                </p>

                <p>
                  To manage your cookies, edit your preferences through your
                  browser’s settings. More details about managing your cookie
                  preferences are available via the following links:
                </p>

                <ul>
                  <li>
                    Website Cookies:{" "}
                    <a
                      href="http://www.allaboutcookies.org/manage-cookies/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      http://www.allaboutcookies.org/manage-cookies/
                    </a>
                  </li>
                  <li>
                    Mobile Cookies:{" "}
                    <a
                      href="http://www.allaboutcookies.org/mobile/index.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      http://www.allaboutcookies.org/mobile/index.html
                    </a>
                  </li>
                  <li>
                    Internet Advertising Bureau (IAB):{" "}
                    <a
                      href="http://www.youronlinechoices.com/uk/your-ad-choices "
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      http://www.youronlinechoices.com/uk/your-ad-choices
                    </a>
                  </li>
                  <li>
                    Network Advertising Initiative (NAI):{" "}
                    <a
                      href="http://optout.networkadvertising.org/?c=1#!/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      http://optout.networkadvertising.org/?c=1#!/
                    </a>
                  </li>
                  <li>
                    The Digital Advertising Alliance (DAA):{" "}
                    <a
                      href="https:/digitaladvertisingalliance.org/ "
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https:/digitaladvertisingalliance.org/
                    </a>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
