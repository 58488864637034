import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import voca from 'voca';
import ReactHtmlParser from 'react-html-parser';
// React Slick Slider
import Slider from 'react-slick';
import axios from 'axios';
import _ from 'lodash';
import config from '../.././config.json';
// Styles
import './HeroBannerCarousel.css';

export default class HeroBannerCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      loading: true,
      screenMD: false,
      screenLG: false
    };
  }
  componentWillMount() {
    let tag = 8;
    axios
      .get(`${config.databaseUrl}wp-json/wp/v2/posts?tags=${tag}&_embed`)
      .then(response => {
        this.setState({
          posts: response.data,
          loading: response.data.length ? false : true
        });
      })
      .catch(error => console.log(error));
  }
  componentDidMount() {
    window.addEventListener('resize', this.onScreenResize);
    this.onScreenResize();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.onScreenResize);
  }
  onScreenResize = () => {
    let currentscreenMD = window.innerWidth <= 992;
    let currentscreenLG = window.innerWidth > 992;
    if (currentscreenMD !== this.state.screenMD) {
      this.setState({
        screenMD: currentscreenMD,
        screenLG: false
      });
    }
    if (currentscreenLG !== this.state.screenLG) {
      this.setState({
        screenMD: false,
        screenLG: currentscreenLG
      });
    }
  };
  createSlideEl = posts => {
    let bgColorArr = ['bg-ub', 'bg-rp', 'bg-tt'];

    if (posts.length > 1) {
      let randomNum = _.random(0, 2);

      let featuredPosts = posts.map((post, index) => {
        // console.log(
        //   `${index}`,
        //   post._embedded['wp:featuredmedia'][0].media_details.sizes
        // );
        let featuredImageFile;
        let featuredImgFull = post._embedded['wp:featuredmedia'][0]
          .media_details.sizes.full
          ? post._embedded['wp:featuredmedia'][0].media_details.sizes.full
              .source_url
          : '';

        let featuredImgMD = post._embedded['wp:featuredmedia'][0].media_details
          .sizes['large']
          ? post._embedded['wp:featuredmedia'][0].media_details.sizes['large']
              .source_url
          : featuredImgFull;

        let featuredImgLG = post._embedded['wp:featuredmedia'][0].media_details
          .sizes['twentyseventeen-featured-image']
          ? post._embedded['wp:featuredmedia'][0].media_details.sizes[
              'twentyseventeen-featured-image'
            ].source_url
          : featuredImgFull;

        // if (
        //   post._embedded['wp:featuredmedia'][0].media_details.sizes[
        //     'twentyseventeen-featured-image'
        //   ]
        // ) {
        //   featuredImageFile =
        //     post._embedded['wp:featuredmedia'][0].media_details.sizes[
        //       'twentyseventeen-featured-image'
        //     ].source_url;
        // } else if (
        //   post._embedded['wp:featuredmedia'][0].media_details.sizes.full
        // ) {
        //   featuredImageFile =
        //     post._embedded['wp:featuredmedia'][0].media_details.sizes.full
        //       .source_url;
        // } else {
        //   featuredImageFile = '';
        // }
        if (this.state.screenMD) {
          featuredImageFile = featuredImgMD;
        }
        if (this.state.screenLG) {
          featuredImageFile = featuredImgLG;
        }
        return (
          <div key={index}>
            <div
              className="hero-card"
              style={{
                backgroundImage: `url(${featuredImageFile})`
              }}
            >
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="hero-text">
                      <p className={`hero-category ${bgColorArr[randomNum]}`}>
                        {post._embedded['wp:term'][0][0].name}
                      </p>
                      <div className="hero-title">
                        <NavLink to={'/posts/' + post.slug}>
                          <p className={`h3 ${bgColorArr[randomNum]}`}>
                            {voca.truncate(
                              ReactHtmlParser(post.title.rendered),
                              '60',
                              '...'
                            )}
                          </p>
                        </NavLink>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      });
      return featuredPosts;
    } else if (posts.length === 1) {
      let randomNum = _.random(0, 2);
      let featuredImageFile;
      let featuredImgFull = posts[0]._embedded['wp:featuredmedia'][0]
        .media_details.sizes.full
        ? posts[0]._embedded['wp:featuredmedia'][0].media_details.sizes.full
            .source_url
        : '';

      let featuredImgMD = posts[0]._embedded['wp:featuredmedia'][0]
        .media_details.sizes['large']
        ? posts[0]._embedded['wp:featuredmedia'][0].media_details.sizes['large']
            .source_url
        : featuredImgFull;

      let featuredImgLG = posts[0]._embedded['wp:featuredmedia'][0]
        .media_details.sizes['twentyseventeen-featured-image']
        ? posts[0]._embedded['wp:featuredmedia'][0].media_details.sizes[
            'twentyseventeen-featured-image'
          ].source_url
        : featuredImgFull;

      if (this.state.screenMD) {
        featuredImageFile = featuredImgMD;
      }
      if (this.state.screenLG) {
        featuredImageFile = featuredImgLG;
      }
      return (
        <div>
          <div
            className="hero-card"
            style={{
              backgroundImage: `url(${featuredImageFile}})`
            }}
          >
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="hero-text">
                    <p className={`hero-category ${bgColorArr[randomNum]}`}>
                      {posts[0]._embedded['wp:term'][0][0].name}
                    </p>
                    <div className="hero-title">
                      <NavLink to={'/posts/' + posts[0].slug}>
                        <p className={`h3 ${bgColorArr[randomNum]}`}>
                          {voca.truncate(
                            ReactHtmlParser(posts[0].title.rendered),
                            '60',
                            '...'
                          )}
                        </p>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <React.Fragment />;
    }
  };
  render() {
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1
    };
    if (!this.state.loading) {
      return (
        <React.Fragment>
          <section id="heroCarousel">
            <Slider {...settings}>
              {this.createSlideEl(this.state.posts)}
            </Slider>
          </section>
        </React.Fragment>
      );
    } else {
      return <React.Fragment />;
    }
  }
}
